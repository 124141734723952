import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form } from "antd";
import { parseHtml } from "../../../Utils/utils";
import { contactUsApi, contactUsQueryApi } from "../../../redux/action/contactus";

const ContactUs = ({ contactUsApi, contactUsData, contactUsQueryApi }) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [submitButton, setSubmitButton] = useState(false);
  useEffect(() => {
    contactUsApi();
  }, []);

  const handleConnectSubmint = () => {
    const data = {
      f_name: fname,
      email: email,
      mobile: mobile,
      message: message,
      l_name: lname,
    };

    contactUsQueryApi(data);
    setInterval(() => {
      window.location.reload(false);
    }, 1000 * 5);
    setSubmitButton(true);
  };

  const contactAddresses = contactUsData && contactUsData.data;
  const contactInfo = contactUsData && contactUsData.contactInfo;
  return (
    <>
      <section className="hero-banner-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="zee-subheading-1">Reach out to Zee Media</div>
              <div className="zee-heading-2">Contact us</div>
              <div className="zee-subheading-1">At Zee, we welcome correspondence from our consumers, investors, career seekers, our partners, and people at large.</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container pt-5 pb-0 pb-md-5">
        <div className="row">
          <div className="col-md-6 mx-auto text-center">
            <div className="zee-heading-1">Get in Touch</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 mx-auto">
            <Form
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              // onFinish={handleConnectSubmint}
            >
              <div className="row">
                <div className="col-md-6 zee-form">
                  <label className="form-label">
                    First Name<small>*</small>
                  </label>
                  <input type="text" className="form-control text-capitalize" value={fname} pattern="[a-zA-Z_&-]+([ ]?[a-zA-Z_&-]+)*" placeholder="Enter First Name" onChange={(e) => setFname(e.target.value)} required />
                </div>
                <div className="col-md-6 zee-form">
                  <label className="form-label">
                    Last Name<small>*</small>
                  </label>
                  <input type="text" className="form-control text-capitalize" value={lname} pattern="[a-zA-Z_&-]+([ ]?[a-zA-Z_&-]+)*" placeholder="Enter Last Name" onChange={(e) => setLname(e.target.value)} required />
                </div>
                <div className="col-md-6 zee-form">
                  <label className="form-label">
                    E-mail Address<small>*</small>
                  </label>
                  <input type="text" className="form-control" value={email} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$" placeholder="Enter E-mail Address" onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="col-md-6 zee-form">
                  <label className="form-label">
                    Phone Number<small>*</small>
                  </label>

                  <input type="text" className="form-control" minLength={10} maxLength={10} value={mobile} pattern="^[0-9\b]+$" placeholder="Enter Phone Number" onChange={(e) => setMobile(e.target.value)} required />
                </div>
                <div className="col-md-12 zee-form">
                  <label className="form-label">
                    Your Message<small>*</small>
                  </label>
                  <textarea rows="5" className="form-control" value={message} placeholder="Enter here..." onChange={(e) => setMessage(e.target.value)} maxLength="200" required></textarea>
                </div>
                <div className="col-md-11 mx-auto my-4">
                  <div className="zee-para-1 text-center">By submitting this form you agree to our terms and conditions and our Privacy Policy which explains how we may collect, use and disclose your personal information including to third parties.</div>
                </div>
                <div className="col-md-11 mx-auto my-2 text-center">
                  <button type="submit" className="btn btn-primary btn-submit zee-btn-3" disabled={submitButton ? "disabled" : ""}>
                    <span>Send Now</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z" />
                    </svg>
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="row my-5 pt-5 justify-content-center">
          <div className="col-md-4">
            <div className="getInTouch">
              <div className="git-ico">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M25.3332 23.9997H6.6665C5.93184 23.9997 5.33317 23.4023 5.33317 22.6663V9.66634L15.1998 17.0663C15.4372 17.245 15.7185 17.333 15.9998 17.333C16.2812 17.333 16.5625 17.245 16.7998 17.0663L26.6665 9.66634V22.6663C26.6665 23.4023 26.0678 23.9997 25.3332 23.9997ZM24.4438 7.99967L15.9998 14.333L7.55584 7.99967H24.4438ZM25.3332 5.33301H6.6665C4.46117 5.33301 2.6665 7.12767 2.6665 9.33301V22.6663C2.6665 24.8717 4.46117 26.6663 6.6665 26.6663H25.3332C27.5385 26.6663 29.3332 24.8717 29.3332 22.6663V9.33301C29.3332 7.12767 27.5385 5.33301 25.3332 5.33301Z" />
                </svg>
              </div>
              <b>E-mail us</b>
              <span>Email us for general queries, including Products and partnership opportunities.</span>
              <a href={contactInfo && `mailto:` + contactInfo.email}>{contactInfo && contactInfo.email}</a>
            </div>
          </div>
          <div className="col-md-4">
            <div className="getInTouch">
              <div className="git-ico">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.2003 26.667C13.3483 26.667 5.33366 18.651 5.33366 8.80033C5.33366 6.88833 6.88833 5.33366 8.80033 5.33366C8.94566 5.33366 9.08966 5.34299 9.23633 5.36166L10.651 11.523C10.4761 11.614 10.2889 11.7119 10.1063 11.8074L10.1062 11.8074L9.93366 11.8977C8.78433 12.4977 7.87766 12.9723 8.35366 14.019C9.92166 18.4803 13.5203 22.0803 17.9283 23.627C19.0514 24.077 19.4872 23.2437 20.0897 22.0916L20.0977 22.0763C20.1669 21.9451 20.2382 21.8083 20.3089 21.6726C20.3661 21.5629 20.4228 21.454 20.4777 21.3497L26.6377 22.763C26.6577 22.907 26.667 23.0523 26.667 23.2003C26.667 25.1123 25.1123 26.667 23.2003 26.667ZM29.0017 21.211C28.851 20.7777 28.4883 20.4523 28.0403 20.3497L20.0883 18.5243C19.6497 18.4257 19.191 18.5497 18.867 18.8617C18.6763 19.043 18.6723 19.0483 17.8057 20.703C14.9657 19.4097 12.639 17.091 11.3003 14.1923C12.9523 13.327 12.9563 13.323 13.139 13.1337C13.451 12.8083 13.5763 12.3497 13.4763 11.9123L11.651 3.96033C11.5483 3.51233 11.2217 3.14833 10.7883 2.99766C10.471 2.88833 10.1443 2.80566 9.82166 2.75366C9.48966 2.69633 9.14699 2.66699 8.80032 2.66699C5.41766 2.66699 2.66699 5.41766 2.66699 8.80033C2.66699 20.123 11.8777 29.3337 23.2003 29.3337C26.5817 29.3337 29.3337 26.583 29.3337 23.2003C29.3337 22.8563 29.303 22.5163 29.247 22.1857C29.1937 21.8523 29.111 21.5257 29.0017 21.211ZM17.3331 10.667C19.5385 10.667 21.3331 12.4617 21.3331 14.667C21.3331 15.4043 21.9291 16.0003 22.6665 16.0003C23.4038 16.0003 23.9998 15.4043 23.9998 14.667C23.9998 10.991 21.0091 8.00033 17.3331 8.00033C16.5971 8.00033 15.9998 8.59633 15.9998 9.33366C15.9998 10.071 16.5971 10.667 17.3331 10.667ZM26.6665 14.667C26.6665 9.52166 22.4785 5.33366 17.3331 5.33366C16.5971 5.33366 15.9998 4.73766 15.9998 4.00033C15.9998 3.26299 16.5971 2.66699 17.3331 2.66699C23.9505 2.66699 29.3331 8.04966 29.3331 14.667C29.3331 15.4043 28.7371 16.0003 27.9998 16.0003C27.2625 16.0003 26.6665 15.4043 26.6665 14.667Z"
                  />
                </svg>
              </div>
              <b>Call us</b>
              <span>Call us to speak to a member of our team. We are always happy to help.</span>
              <a href="#">{contactInfo && contactInfo.contact_no}</a>
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="getInTouch">
              <div className="git-ico">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.9998 25.3337C22.5292 25.3337 21.3332 24.1377 21.3332 22.667C21.3332 21.1963 22.5292 20.0003 23.9998 20.0003C25.4705 20.0003 26.6665 21.1963 26.6665 22.667C26.6665 24.1377 25.4705 25.3337 23.9998 25.3337ZM7.99984 20.0003C9.4705 20.0003 10.6665 21.1963 10.6665 22.667C10.6665 24.1377 9.4705 25.3337 7.99984 25.3337C6.52917 25.3337 5.33317 24.1377 5.33317 22.667C5.33317 21.1963 6.52917 20.0003 7.99984 20.0003ZM15.9998 2.66699C8.64784 2.66699 2.6665 8.86699 2.6665 16.4883V22.667C2.6665 25.6083 5.0585 28.0003 7.99984 28.0003C10.9412 28.0003 13.3332 25.6083 13.3332 22.667C13.3332 19.7257 10.9412 17.3337 7.99984 17.3337C7.02384 17.3337 6.12117 17.6163 5.33317 18.0763V16.4883C5.33317 10.3377 10.1185 5.33366 15.9998 5.33366C21.8812 5.33366 26.6665 10.3377 26.6665 16.4883V18.0763C25.8785 17.6163 24.9758 17.3337 23.9998 17.3337C21.0585 17.3337 18.6665 19.7257 18.6665 22.667C18.6665 25.6083 21.0585 28.0003 23.9998 28.0003C26.9412 28.0003 29.3332 25.6083 29.3332 22.667V16.4883C29.3332 8.86699 23.3518 2.66699 15.9998 2.66699Z"
                  />
                </svg>
              </div>
              <b>Support</b>
              <span>Check out helpful resources, FAQs and developer tools.</span>
              <a href={contactInfo && `mailto:` + contactInfo.supports} className="sales-email">
                Support Center
              </a>
            </div>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="OfficeAddressWrp">
          <div className="row">
            {contactAddresses &&
              contactAddresses.map((item, index) => (
                <div className="col-md-6" key={index}>
                  <div className="OfficeAddress">
                    <b>{item && item.city} Office</b>
                    <span>
                      <span>{item && parseHtml(item.office_name)}</span>
                      <span>{item && parseHtml(item.address)},</span>
                      <span>{item && parseHtml(item.address1)}</span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ContactUsReducer } = state;
  const { contactUsData } = ContactUsReducer;
  return {
    contactUsData: ContactUsReducer.contactUsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    contactUsApi: () => dispatch(contactUsApi()),
    contactUsQueryApi: (data) => dispatch(contactUsQueryApi(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
