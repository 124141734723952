import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { CONTACT_QUERY, CONTACT_US } from "../constant";
import { handleSuccess } from "./common";


export const contactUsApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getContactUsRequest());
        axios
            .get(CONTACT_US, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getContactUsDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}

export const contactUsQueryApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getContactUsRequest());
        axios
            .post(CONTACT_QUERY, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(handleSuccess({ message: response.data.message }));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}


export const getContactUsRequest = data => {
    return {
        type: "CONTACTUS_Data_REQUESTED",
    };
};
export const getContactUsDataRespond = data => {
    return {
        type: "CONTACTUS_DATA_RESPONSE",
        data: data,
    };
}
// export const handleSuccess = (data) => {
//     return {
//         type: "SHOW_TOAST",
//         data,
//         toastType: "success",
//     };
// };
export const handleError = error => {
    return {
        type: "CONTACTUS_ERROR",
        error,
    };
};