import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { CHANNEL_APPS, KEY_MATRICS, NETWORK_DATA, SMARTERS, META_TAG_URL, PAGE_BLOCKS_URL } from "../constant";

export const networDataApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(NETWORK_DATA, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getNetworkDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const samrtersDataApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(SMARTERS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getSmarterDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const matricsDataApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(KEY_MATRICS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getMatricsDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const channelAppsDataApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(CHANNEL_APPS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getChannelAppsDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const metaTagApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(META_TAG_URL, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getMetaTagDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const pageBlocksApi = (data) => {

    return (dispatch, getState) => {
        dispatch(getHomeRequest());
        axios
            .get(PAGE_BLOCKS_URL, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getPageBlockDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const getHomeRequest = data => {
    return {
        type: "HOME_Data_REQUESTED",
    };
};

export const getNetworkDataRespond = data => {
    return {
        type: "NETWORK_DATA_RESPONSE",
        data: data,
    };
}

export const getPageBlockDataRespond = data => {
    return {
        type: "PAGE_BLOCK_DATA_RESPONSE",
        data: data,
    };
}

export const getMetaTagDataRespond = data => {
    return {
        type: "META_TAG_DATA_RESPONSE",
        data: data,
    };
}

export const getSmarterDataRespond = (data) => {
    return {
        type: "SMARTER_DATA_RESPONSE",
        data: data,
    };
}

export const getMatricsDataRespond = data => {
    return {
        type: "Matrics_DATA_RESPONSE",
        data: data,
    };
}

export const getChannelAppsDataRespond = data => {
    return {
        type: "CHANNEL_APPS_DATA_RESPONSE",
        data: data,
    };
}


export const handleError = error => {
    return {
        type: "HOME_ERROR",
        error,
    };
};