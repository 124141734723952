import React, { useEffect } from "react";
import { connect } from "react-redux";
import { aboutUsDataApi } from "../../../redux/action/about";
import { samrtersDataApi } from "../../../redux/action/home";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { parseHtml } from "../../../Utils/utils";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const About = ({ smarterData, samrtersDataApi, aboutUsDataApi, aboutData }) => {
  useEffect(() => {
    samrtersDataApi();
    aboutUsDataApi();
  }, []);

  const visions = aboutData && aboutData.visions;
  const missions = aboutData && aboutData.missions;
  const awards = aboutData && aboutData.awards;
  const directors = aboutData && aboutData.directors;
  const seniors = aboutData && aboutData.seniors;
  const aboutVideo = aboutData && aboutData.aboutVideo;

  const aboutCarsole = {
    loop: true,
    dots: false,
    margin: 20,
    lazyLoad: true,
    navText: [
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M11.1352 14.6333C10.9169 14.6333 10.6994 14.5483 10.536 14.3792L7.31686 11.0458C7.00186 10.7183 7.00603 10.1992 7.3277 9.87751L10.661 6.54418C10.986 6.21835 11.5135 6.21835 11.8394 6.54418C12.1644 6.87001 12.1644 7.39668 11.8394 7.72251L9.0852 10.4767L11.7344 13.2208C12.0544 13.5525 12.0452 14.08 11.7144 14.3992C11.5519 14.5558 11.3435 14.6333 11.1352 14.6333"/></svg></svg></span>',
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M8.75005 14.6335C8.53671 14.6335 8.32338 14.5518 8.16088 14.3893C7.83505 14.0635 7.83505 13.5368 8.16088 13.211L10.915 10.4568L8.26505 7.71267C7.94588 7.38101 7.95505 6.85351 8.28588 6.53434C8.61755 6.21517 9.14505 6.22434 9.46421 6.55434L12.6825 9.88767C12.9984 10.2152 12.9942 10.7343 12.6725 11.056L9.33921 14.3893C9.17671 14.5518 8.96338 14.6335 8.75005 14.6335"/></svg></span>',
    ],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <section className="hero-banner-page aboutUs-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="zee-heading-2">About us</div>
              <div className="hero-banner-para-1">Zee Media Corporation Limited is India's one of the largest News Network with 14 news channels in 7 different languages touching more than 183 Million viewers and 32 digital properties like zeenews.com</div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- =============== Who we are Start =============== --> */}

      <div className="container pt-5">
        <div className="row">
          <div className="col-md-7">
            <div className="d-flex flex-column justify-content-center h-100">
              <div className="zee-heading-1">Who are we</div>
              <div className="zee-para-1 text-justify">Zee Media network is one network that began early, grew steadily, adapted along, sustained all weathers and today stands as one of India’s largest, widest and most diverse-platform news network.  Zee Media Corporation Limited has interests in Global, National and Regional news channels and Digital news publishing. The Company operates 14 News channels across various genre and languages. The Company also operates 32 digital properties and 7 mobile applications. The digital publishing business of the Company has been consolidated under a subsidiary company ‘Indiadotcom Digital Private Limited’ (IDPL). Zee News, the flagship channel of the network is a household name among India’s news channels and among the flagbearers of free, fearless and foremost journalism.</div>
            </div>
          </div>
          <div className="col-md-4 offset-md-1 mt-4 mt-md-0">
            <div className="row g-4">
              <div className="col-md-6 offset-md-3">
                <div className="wwa-data">
                  <b>{smarterData && smarterData.data && smarterData.data.news_channels}</b>
                  <span>News Channels</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wwa-data">
                  <b>{smarterData && smarterData.data && smarterData.data.diiferent_language}</b>
                  <span>Different languages</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wwa-data">
                  <b>{smarterData && smarterData.data && smarterData.data.viewers}</b>
                  <span>Viewers</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wwa-data">
                  <b>{smarterData && smarterData.data && smarterData.data.monthly_unique_visitors}</b>
                  <span>Monthly unique visitors</span>
                </div>
              </div>
              <div className="col-md-6">
                <div className="wwa-data">
                  <b>{smarterData && smarterData.data && smarterData.data.digital_properties}</b>
                  <span>Digital Properties</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Who we are End =============== --> */}

      {/* <!-- =============== Video Section Start =============== --> */}
      <div className="container my-md-5 my-0 py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="video-frame">
              <video key={aboutVideo && IMAGE_BASE_URL + "" + aboutVideo.video} autoPlay className="rounded actual-video" controls={true} controlsList="nodownload">
                <source src={aboutVideo && IMAGE_BASE_URL + "" + aboutVideo.video} type="video/mp4" />
                <source src={aboutVideo && IMAGE_BASE_URL + "" + aboutVideo.video} type="video/ogg" />
              </video>
              {/* <img src={require("../static/img/icons/play-btn.svg").default} alt="play-btn" className="play-btn" /> */}
              {/* <img src={require("../static/img/careers-video.png")} alt="video-banner" /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Video Section End =============== --> */}

      {/* <!-- =============== Vision & Mission Section Start =============== --> */}
      <div className="container mb-5 d-none">
        <div className="row">
          <div className="col-md-6">
            <div className="vision-mission">
              <div className="zee-heading-1">Our Vision</div>
              <div className="zee-para-1">{visions && parseHtml(visions.description)}</div>
              <div className="vm-img-wrp">
                <div className="vm-img">
                  <img src={visions && IMAGE_BASE_URL + "" + visions.image} alt="Vision" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="vision-mission">
              <div className="zee-heading-1">Our Mission</div>
              <div className="zee-para-1">{missions && parseHtml(missions.description)}</div>
              <div className="vm-img-wrp">
                <div className="vm-img">
                  <img src={missions && IMAGE_BASE_URL + "" + missions.image} alt="Mission" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Vision & Mission Section End =============== --> */}

      {/* <!-- =============== Core Values Section Start =============== --> */}
      <div className="container py-md-5 py-0">
        <div className="row py-5">
          <div className="col-md-7 mx-auto text-center">
            <div className="zee-heading-1">Our Core Values</div>
            <div className="zee-para-1 justify-content-center">Our network is built on an impressive heritage of pioneering success and growth. Our long legacy and our core values define the spirit of our employees and our organization.</div>
          </div>
          <div className="col-lg-10 col-sm-12 mx-auto">
            <div className="values-wrp">
              <div className="values-left">
                <div className="main-val">
                  <img src={require("../static/img/icons/CustomerFirst.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Customer First</b>
                    <span>To anticipate, understand and meet needs of internal or external customers.</span>
                  </div>
                </div>
                <div className="main-val">
                  <img src={require("../static/img/icons/BeFrugal.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Be Frugal</b>
                    <span>To focus on need-based resources utilization.</span>
                  </div>
                </div>
                <div className="main-val">
                  <img src={require("../static/img/icons/Speed&Agility.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Speed & Agility</b>
                    <span>To continue to deliver on responsibilities while responding to the speed and agility evolving environment.</span>
                  </div>
                </div>
                <div className="main-val">
                  <img src={require("../static/img/icons/AccountabilityForResults.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Accountability For Results</b>
                    <span>To take ownership of decisions or actions for self and team.</span>
                  </div>
                </div>
              </div>
              <div className="mid-arrows"></div>
              <div className="values-right">
                <div className="main-val">
                  <img src={require("../static/img/icons/BigHairyAudaciousGoal.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Big Hairy Audacious Goal</b>
                    <span>To set for ourselves clear, compelling and audacious goals.</span>
                  </div>
                </div>
                <div className="main-val">
                  <img src={require("../static/img/icons/RespectHumility&Integrity.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Respect, Humility & Integrity</b>
                    <span>To respect and honor each individual’s unique talents.</span>
                  </div>
                </div>
                <div className="main-val">
                  <img src={require("../static/img/icons/SolveBigProblems.svg").default} alt="core-value" />
                  <div className="main-val-data">
                    <b>Solve Big Problems</b>
                    <span>To identify and resolve problems which have a huge impact on business.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Core Values Section End =============== --> */}

      {/* <!-- =============== Awards and Recognition Section Start =============== --> */}
      <section className="AwardsRecognition">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto text-center">
              <div className="zee-heading-1">Awards and Recognition</div>
              <div className="zee-para-1 justify-content-center">Our network is built on an impressive heritage of pioneering success and growth. Our long legacy and our core values define the spirit of our employees and our organization.</div>
            </div>
            <div className="col-md-12">
              {awards && (
                <OwlCarousel className="owl-theme life-at-zee owl-bottom-nav" {...aboutCarsole}>
                  {awards &&
                    awards.map((item, index) => (
                      <div className="item" key={index}>
                        <div className="award-item">
                          <b>{item && item.year}</b>
                          <span>{item && item.name}</span>
                          <img src={item && IMAGE_BASE_URL + "" + item.image} alt="Award" />
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              )}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Awards and Recognition Section End =============== --> */}

      {/* <!-- =============== Directors & Senior Management Section Start =============== --> */}
      <div className="container py-5 mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="zmcl-navTabs-2 D-SM-Wrp pt-3">
              <nav>
                <div className="nav nav-tabs" role="tablist">
                  <span className="zee-heading-1">Board Of</span>
                  <button className="nav-link active" id="nav-tab-1" data-bs-toggle="tab" data-bs-target="#nav-target-tab-1" type="button" role="tab" aria-controls="nav-target-tab-1" aria-selected="true">
                    <span className="zee-heading-1">Directors</span>
                  </button>
                  <button className="nav-link" id="nav-tab-2" data-bs-toggle="tab" data-bs-target="#nav-target-tab-2" type="button" role="tab" aria-controls="nav-target-tab-2" aria-selected="false">
                    <span className="zee-heading-1">Senior Management</span>
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-target-tab-1" role="tabpanel" aria-labelledby="nav-tab-1">
                  <div className="row g-4">
                    {directors &&
                      directors.map((item, index) => (
                        <div className="col-md-3" key={index}>
                          <div className="DSM-Wrp">
                            <div className="dsm-dp">
                              <img src={item && IMAGE_BASE_URL + "" + item.image} alt="management" />
                            </div>
                            <div className="dsm-data">
                              <div className="dsm-name">
                                <b>{item && item.name}</b>
                                {/* <div className="dsm-social">
                                  <a href={item && item.linkedin_link} target="_blank" rel="noopener noreferrer">
                                    <img src={require("../static/img/icons/social-linkedin.svg").default} alt="social-linkedin" />
                                  </a>
                                  <a href={item && item.twitter_link} target="_blank" rel="noopener noreferrer">
                                    <img src={require("../static/img/icons/social-twitter.svg").default} alt="social-twitter" />
                                  </a>
                                </div> */}
                              </div>
                              <span>{item && item.designation}</span>
                              <p>{item && parseHtml(item.description)}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="tab-pane  fade" id="nav-target-tab-2" role="tabpanel" aria-labelledby="nav-tab-2">
                  <div className="row g-4">
                    {seniors &&
                      seniors.map((item, index) => (
                        <div className="col-md-3" key={index}>
                          <div className="DSM-Wrp">
                            <div className="dsm-dp">
                              <img src={item && IMAGE_BASE_URL + "" + item.image} alt="management" />
                            </div>
                            <div className="dsm-data">
                              <div className="dsm-name">
                                <b>{item && item.name}</b>
                                {/* <div className="dsm-social">
                                  <a href={item && item.linkedin_link} target="_blank" rel="noopener noreferrer">
                                    <img src={require("../static/img/icons/social-linkedin.svg").default} alt="social-linkedin" />
                                  </a>
                                  <a href={item && item.twitter_link} target="_blank" rel="noopener noreferrer">
                                    <img src={require("../static/img/icons/social-twitter.svg").default} alt="social-twitter" />
                                  </a>
                                </div> */}
                              </div>
                              <span>{item && item.designation}</span>
                              <p>{item && parseHtml(item.description)}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Directors & Senior Management Section End =============== --> */}
    </>
  );
};

const mapStateToProps = (state) => {
  const { HomeReducer, AboutReducer } = state;
  const { eventData } = HomeReducer;
  const { aboutData } = AboutReducer;

  return {
    smarterData: HomeReducer.smarterData,
    aboutData: AboutReducer.aboutData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    samrtersDataApi: () => dispatch(samrtersDataApi()),
    aboutUsDataApi: () => dispatch(aboutUsDataApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(About);
