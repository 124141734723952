const initState = {
    investerData: [],
    investerSearchData: [],
    error: null
};

const InvesterReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "INVESTER_Data_REQUESTED":
            return {
                ...state,
            };
        case "INVESTER_DATA_RESPONSE":
            return {
                ...state,
                investerData: data,
            };
        case "INVESTER_SEARCH_DATA_RESPONSE":
            return {
                ...state,
                investerSearchData: data,
            };
        case "INVESTER_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }

}
export default InvesterReducer;