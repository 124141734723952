const initState = {
    privacyData: [],
    termsData: [],
    socialMediaData: [],
    companyPolicyData: {},
    error: null
};

const PrivacyReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "PRIVACY_Data_REQUESTED":
            return {
                ...state,
            };
        case "PRIVACY_DATA_RESPONSE":
            return {
                ...state,
                privacyData: data,
            };
        case "TERMS_DATA_RESPONSE":
            return {
                ...state,
                termsData: data,
            };
        case "COMPANY_POLICY_DATA_RESPONSE":
            return {
                ...state,
                companyPolicyData: data,
            };
        case "Social_DATA_RESPONSE":
            return {
                ...state,
                socialMediaData: data,
            };
        case "PRIVACY_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }

}
export default PrivacyReducer;