import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Input } from "antd";

function Corporate({ openModal, handleCancel, handleCorporate }) {
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [office, setOffice] = useState("");
    const [emailError, setEmailError] = useState("");

    const onFinish = () => {
        const data = {
            name: name,
            mobile: mobile,
            email: email,
            office: office,
        };
        handleCorporate(data);
    };

    return (
        <>
            <Modal show={openModal} onHide={handleCancel} className="modal" centered backdrop={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Fill the form</Modal.Title>
                </Modal.Header>
                <Modal.Body className="CorpPrstn">
                    <Form onFinish={onFinish}>
                        <div className="row">
                            <div className="col-md-12 zee-form">
                                <label className="form-label">
                                    Name<small>*</small>
                                </label>
                                <Input type="text" name="name" value={name} pattern="[a-zA-Z_&-]+([ ]?[a-zA-Z_&-]+)*" className="form-control" required placeholder="Eg: John Doe" onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col-md-12 zee-form">
                                <label className="form-label">
                                    Mobile Number<small>*</small>
                                </label>

                                <Input type="text" name="mobile" className="form-control" placeholder="Eg: 991234XXXX" minLength={10} maxLength={10} pattern="^[0-9\b]+$" value={mobile} required onChange={(e) => setMobile(e.target.value)} />
                            </div>
                            <div className="col-md-12 zee-form">
                                <label className="form-label">
                                    Email<small>*</small>
                                </label>
                                <Input type="email" name="email" nofreeemail={true} className="form-control" placeholder="Please add official email address" value={email} pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$" required onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="col-md-12 zee-form">
                                <label className="form-label">
                                    Company<small>*</small>
                                </label>
                                <Input type="text" name="office" className="form-control" placeholder="Eg: Noida" value={office} onChange={(e) => setOffice(e.target.value)} />
                            </div>
                            <div className="col-md-12 zee-form text-center">
                                <button type="submit" className="zee-btn-3">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Corporate;
