import React from "react";
import { IMAGE_BASE_URL } from "../../../redux/constant";

const LinearBroadCast = ({ linearBroadCast }) => {
    return (<>
        <div className="brcst-chLogo">
            {linearBroadCast && linearBroadCast.length > 0 && linearBroadCast.map((item, index) =>
                <div className="chLogo-wrp">
                    {/* <a href={item && item.channel_link ? item.channel_link : "javascript:void();"} target="_vishnu"> */}
                    <img src={item && IMAGE_BASE_URL + '' + item.channel_logo} alt="channel logo" />
                    {/* </a> */}
                </div>
            )}

        </div>
    </>);
}

export default LinearBroadCast;