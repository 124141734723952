import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { networDataApi, samrtersDataApi, matricsDataApi, channelAppsDataApi } from "../../../redux/action/home";
import Modal from "react-bootstrap/Modal";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { parseHtml } from "../../../Utils/utils";
import { Tooltip } from "antd";

const Home = ({ networDataApi, networData, channelAppsDataApi, channelAppsData, samrtersDataApi, smarterData, matricsDataApi, matricsData }) => {
  const [show, setShow] = useState(false);
  const [appShow, setAppShow] = useState(false);
  const [filterData, setFilterData] = useState();
  useEffect(() => {
    networDataApi();
    samrtersDataApi();
    matricsDataApi();
    channelAppsDataApi();
  }, []);

  const appsData = channelAppsData && channelAppsData.apps;

  const handleAppsData = (id) => {
    const data = appsData.filter((f) => f.id == id);
    // console.log(data);
    setFilterData(data);
    setAppShow(true);
  };

  return (
    <>
      <div className="container">
        <section className="hero-section">
          <div className="row">
            <div className="col-lg-8 col-sm-12 mx-auto">
              <div className="hero-sub">Welcome to Zee Media</div>
              <div className="hero-title">
                <span>India's largest </span>
                <span>News Network</span>
              </div>
            </div>
          </div>
        </section>

        <div className="zee-data">
          <ul>
            <li>
              <b>{networData && networData.data && networData.data.users}</b>
              <span>Users</span>
            </li>
            <li>
              <b>{networData && networData.data && networData.data.broadcast}</b>
              <span>Broadcast</span>
            </li>
            <li>
              <b>{networData && networData.data && networData.data.social_media}</b>
              <span>Social Media</span>
            </li>
            <li>
              <b>{networData && networData.data && networData.data.digital}</b>
              <span>Digital</span>
            </li>
          </ul>
        </div>
      </div>
      <section className="container py-5 my-3">
        <div className="row">
          <div className="col-md-4">
            <div className="bbg-bg">
              <img src={require("../static/img/logo-white.svg").default} alt="zee-logo" />
            </div>
          </div>
          <div className="col-md-8 mt-5 mt-md-0">
            <div className="zee-heading-1">
              <span>Bigger, Better and Smarter</span>
            </div>
            <p className="zee-para-1 text-justify">Zee Media Corporation Limited has revolutionised the Media & Entertainment sector, by delivering the best of entertainment and news experiences for audiences in India and across the globe. For over 30 years, it has meticulously created a deep legacy of credibility, excellence, trust, and bipartisanship. Today the ZMCL universe has expanded into a vast network comprising more than 30 legendary digital platforms, delivering information in 10 different languages. It reaches millions of viewers every month and covers every discipline that impacts the society and its people.</p>
            <ul className="BBS-list">
              <li>
                <b>{smarterData && smarterData.data && smarterData.data.news_channels}</b>
                <span>News Channels</span>
              </li>
              <li>
                <b>{smarterData && smarterData.data && smarterData.data.diiferent_language}</b>
                <span>Different Languages</span>
              </li>
              <li>
                <b>{smarterData && smarterData.data && smarterData.data.digital_properties}</b>
                <span>Digital Properties</span>
              </li>
              <li>
                <b>{smarterData && smarterData.data && smarterData.data.viewers}</b>
                <span>Viewers</span>
              </li>
              <li>
                <b>{smarterData && smarterData.data && smarterData.data.monthly_unique_visitors}</b>
                <span>Monthly Unique Visitors</span>
              </li>
            </ul>
            <div className="text-center text-md-start">
              <a
                href="https://zeemedia.in/"
                className="zee-btn"
                onClick={(e) => {
                  e.preventDefault();
                  setShow(true);
                }}
              >
                <svg width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M12 14.5996V9.4006L14.809 11.9996L12 14.5996ZM12.839 7.4526C12.341 6.9906 11.614 6.8706 10.988 7.1456C10.378 7.4116 10 7.9806 10 8.6296V15.3706C10 16.0196 10.378 16.5886 10.988 16.8546C11.211 16.9526 11.448 16.9996 11.681 16.9996C12.104 16.9996 12.519 16.8446 12.838 16.5486L16.48 13.1786C16.811 12.8726 17 12.4436 17 11.9996C17 11.5566 16.811 11.1266 16.48 10.8216L12.839 7.4526ZM12.5 20C8.089 20 4.5 16.411 4.5 12C4.5 7.589 8.089 4 12.5 4C16.911 4 20.5 7.589 20.5 12C20.5 16.411 16.911 20 12.5 20ZM12.5 2C6.986 2 2.5 6.486 2.5 12C2.5 17.514 6.986 22 12.5 22C18.014 22 22.5 17.514 22.5 12C22.5 6.486 18.014 2 12.5 2Z" fill="currentColor" />
                </svg>
                <span>Watch Video</span>
              </a>
            </div>
            <Modal show={show} onHide={() => setShow(false)} centered size="xl">
              <video autoplay="false" controls={true} controlsList="nodownload" className="rounded">
                <source src={smarterData && smarterData.data && IMAGE_BASE_URL + "" + smarterData.data.video_link} type="video/mp4" />
              </video>
            </Modal>
          </div>
        </div>
      </section>
      <section className="light-bg py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="zee-subhead-1">Our Network</div>
              <div className="zee-heading-1">
                <span>One Network-Many</span>
                <span>Achievers</span>
              </div>
              <p className="zee-para-1">Our network is built on an impressive heritage of pioneering success and growth. Our long legacy and our core values define the spirit of our employees and our organisation.</p>
              <ul className="zee-list">
                <li>India's First Private News Broadcaster</li>
                <li>27 Years, and getting stronger and bigger</li>
                <li>Largest News Network Cumulative of TV & Digital</li>
                <li>14 News Channels, 5 Digital News Channels, 7 News App, 32 Digital Properties, 7 languages</li>
                <li>India’s First Global News Channel-WION</li>
                <li>17.28 Billion Pageviews for the Digital News Portfolio For FY22</li>
              </ul>
            </div>
            <div className="col-md-5">
              <img src={require("../static/img/OurNetwork.svg").default} alt="zee-logo" className="img-fluid w-100" />
            </div>
          </div>
        </div>
      </section>
      <section className="container py-5 my-0 my-md-5">
        <div className="row">
          <div className="col-md-7 mx-auto text-center">
            <div className="zee-heading-1">
              <span>Key Metrics Of Our Robust</span>
              <span>Brand Engagement</span>
            </div>
            <p className="zee-para-1">Engaging and empowering our global audience with news of every second. Our Iconic flagship brands are optimised to operate through a wide variety of platforms. Simplifying the access to a plethora of well researched news offered by our most influential and expert journalist.</p>
          </div>
          <div className="col-md-12 mt-5">
            <div className="row">
              <div className="col-md-3">
                <div className="key-metrics">
                  <img src={require("../static/img/icons/KeyMetrics-1.svg").default} alt="key-metrics" />
                  <b>{matricsData && matricsData.data && matricsData.data.page_view_per_month}</b>
                  <span>Pageviews Per Month</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="key-metrics">
                  <img src={require("../static/img/icons/KeyMetrics-2.svg").default} alt="key-metrics" />
                  <b>{matricsData && matricsData.data && matricsData.data.youtube_channel_subscriber}</b>
                  <span>Youtube Channel Subscriber</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="key-metrics">
                  <img src={require("../static/img/icons/KeyMetrics-3.svg").default} alt="key-metrics" />
                  <b>{matricsData && matricsData.data && matricsData.data.facebook_likes}</b>
                  <span>Facebook Likes</span>
                </div>
              </div>
              <div className="col-md-3">
                <div className="key-metrics">
                  <img src={require("../static/img/icons/KeyMetrics-4.svg").default} alt="key-metrics" />
                  <b>{matricsData && matricsData.data && matricsData.data.twitter_followers}</b>
                  <span>Twitter Followers</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="live-app">
        <div className="container">
          <div className="row align-items-center">
            {/* {channelAppsData &&
              channelAppsData.data &&
              channelAppsData.data.map((item, index) => ( */}
            {appShow ? (
              <>
                {filterData &&
                  filterData.map((item, index) => (
                    <div className="col-md-5 text-center text-md-start" key={index}>
                      <div className="zee-heading-1 mb-3">
                        <span>{item && item.app_name}</span>
                        <span>Live APP</span>
                      </div>
                      <p className="zee-para-1 mb-5">{item && parseHtml(item.description)}</p>
                      <a href={item && item.app_store_link} className="zee-btn-2 me-md-4 mb-4" target="_blank" rel="noreferrer">
                        <img src={require("../static/img/icons/app-store.svg").default} alt="app-store" />
                        <span>Download App Store</span>
                      </a>
                      <a href={item && item.play_store_link} className="zee-btn-2 me-md-4 mb-4" target="_blank" rel="noreferrer">
                        <img src={require("../static/img/icons/play-store.svg").default} alt="play-store" />
                        <span>Download Play Store</span>
                      </a>
                    </div>
                  ))}
              </>
            ) : (
              <>
                <div className="col-md-5 text-center text-md-start">
                  <div className="zee-heading-1 mb-3">
                    <span>{channelAppsData && channelAppsData.data && channelAppsData.data.app_name}</span>
                    <span>Live APP</span>
                  </div>
                  <p className="zee-para-1 mb-5">{channelAppsData && channelAppsData.data && parseHtml(channelAppsData.data.description)}</p>
                  <a href={channelAppsData && channelAppsData.data && channelAppsData.data.app_store_link} className="zee-btn-2 me-md-4 mb-4" target="_blank" rel="noreferrer">
                    <img src={require("../static/img/icons/app-store.svg").default} alt="app-store" />
                    <span>Download App Store</span>
                  </a>
                  <a href={channelAppsData && channelAppsData.data && channelAppsData.data.play_store_link} className="zee-btn-2 me-md-4 mb-4" target="_blank" rel="noreferrer">
                    <img src={require("../static/img/icons/play-store.svg").default} alt="play-store" />
                    <span>Download Play Store</span>
                  </a>
                </div>
              </>
            )}
            <div className="col-md-7 text-center">
              <div className="app-circle-wrp">
                <div className="app-circle">
                  {appsData &&
                    appsData.map((item, index) => (
                      <Tooltip placement="top" title={item && item.app_name} key={index}>
                        <span onClick={(e) => handleAppsData(item && item.id)} key={index}>
                          <img src={item && IMAGE_BASE_URL + "" + item.app_logo} alt="zee-icon" className="img-fluid rotate" />
                        </span>
                      </Tooltip>
                    ))}
                </div>
                <div className="live-logo-wrp">
                  <div className="live-logo">
                    <img src={require("../static/img/logo-white.svg").default} alt="zee-logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { HomeReducer } = state;
  return {
    networData: HomeReducer.networData,
    smarterData: HomeReducer.smarterData,
    matricsData: HomeReducer.matricsData,
    channelAppsData: HomeReducer.channelAppsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    networDataApi: () => dispatch(networDataApi()),
    samrtersDataApi: () => dispatch(samrtersDataApi()),
    matricsDataApi: () => dispatch(matricsDataApi()),
    channelAppsDataApi: () => dispatch(channelAppsDataApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
