import React from "react";
import { IMAGE_BASE_URL } from "../../../redux/constant";

const DigitalBroadCast = ({ digitalBroadCast }) => {
    return (<div className="brcst-chLogo">
        {digitalBroadCast && digitalBroadCast.length > 0 && digitalBroadCast.map((item, index) =>
            <div className="chLogo-wrp">
                {/* <a href={item && item.channel_link ? item.channel_link : "javascript:void();"} target="_vishnu"> */}
                <img src={item && IMAGE_BASE_URL + '' + item.channel_logo} alt="channel logo" />
                {/* </a> */}
            </div>
        )}
    </div>);
}

export default DigitalBroadCast;