import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { eventDetailsApi } from "../../../redux/action/event";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { WebRoutes } from "../../../routes";
import { parseHtml } from "../../../Utils/utils";
import OwlCarousel from "react-owl-carousel";

const EventDetails = ({ eventDetailsApi, eventDetailData }) => {
  const [show, setShow] = useState(false);
  const [videoData, setVideoData] = useState();
  const eventID = localStorage.getItem("eventID");
  useEffect(() => {
    eventDetailsApi(eventID);
  }, []);
  const category = eventDetailData && eventDetailData.data && [...new Set(eventDetailData.data.map((q) => q.category.name))];
  const eventImages = eventDetailData && eventDetailData.eventImages && [...new Set(eventDetailData.eventImages.map((q) => q.image))];
  const eventVideos = eventDetailData && eventDetailData.eventVideos && [...new Set(eventDetailData.eventVideos.map((q) => q.video))];

  const lifeAtZee = {
    loop: false,
    dots: false,
    margin: 20,
    navText: [
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M11.1352 14.6333C10.9169 14.6333 10.6994 14.5483 10.536 14.3792L7.31686 11.0458C7.00186 10.7183 7.00603 10.1992 7.3277 9.87751L10.661 6.54418C10.986 6.21835 11.5135 6.21835 11.8394 6.54418C12.1644 6.87001 12.1644 7.39668 11.8394 7.72251L9.0852 10.4767L11.7344 13.2208C12.0544 13.5525 12.0452 14.08 11.7144 14.3992C11.5519 14.5558 11.3435 14.6333 11.1352 14.6333"/></svg></svg></span>',
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M8.75005 14.6335C8.53671 14.6335 8.32338 14.5518 8.16088 14.3893C7.83505 14.0635 7.83505 13.5368 8.16088 13.211L10.915 10.4568L8.26505 7.71267C7.94588 7.38101 7.95505 6.85351 8.28588 6.53434C8.61755 6.21517 9.14505 6.22434 9.46421 6.55434L12.6825 9.88767C12.9984 10.2152 12.9942 10.7343 12.6725 11.056L9.33921 14.3893C9.17671 14.5518 8.96338 14.6335 8.75005 14.6335"/></svg></span>',
    ],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  const handleVideos = (video_url) => {
    setShow(true);
    setVideoData(video_url);
  };
  // console.log(eventDetailData && eventDetailData.data);
  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} centered size="xl">
        <video autoplay="false" className="rounded">
          <source src={videoData} type="video/mp4" />
        </video>
      </Modal>
      <section className="hero-banner-page truckAasana-banner-inner" style={{ backgroundImage: `url(${eventDetailData && eventDetailData.eventData && IMAGE_BASE_URL + "" + eventDetailData.eventData.image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto text-center">{eventDetailData && eventDetailData.eventData && eventDetailData.eventData.event_name && <div className="zee-heading-1">{eventDetailData.eventData.event_name.length >= 30 ? eventDetailData.eventData.event_name.substring(0, 30) + "..." : eventDetailData.eventData.event_name}</div>}</div>
            <div className="col-md-8 mx-auto text-center mb-5 pb-5">{eventDetailData && eventDetailData.eventData && eventDetailData.eventData.description && <div className="hero-banner-para-2 justify-content-center">{eventDetailData.eventData.description.length >= 160 ? eventDetailData.eventData.description.substring(0, 160) + "..." : eventDetailData.eventData.description}</div>}</div>
          </div>
        </div>
      </section>

      <div className="container mt-5 py-4 TruckAasanaContent">
        <div className="row">
          <div className="col-md-12 mb-4">
            <Link to={WebRoutes.ADVERTISEWITHUS} className="zee-back-btn">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.8333 9.16643H5.94575L8.97325 5.53309C9.26825 5.17976 9.21992 4.65393 8.86659 4.35976C8.51242 4.06476 7.98742 4.11309 7.69325 4.46643L3.52659 9.46643C3.49409 9.5056 3.47742 9.55143 3.45325 9.59476C3.43325 9.62976 3.40909 9.65976 3.39409 9.69809C3.35659 9.79393 3.33409 9.89476 3.33409 9.99643C3.33409 9.99726 3.33325 9.99893 3.33325 9.99976C3.33325 10.0006 3.33409 10.0023 3.33409 10.0031C3.33409 10.1048 3.35659 10.2056 3.39409 10.3014C3.40909 10.3398 3.43325 10.3698 3.45325 10.4048C3.47742 10.4481 3.49409 10.4939 3.52659 10.5331L7.69325 15.5331C7.85825 15.7306 8.09492 15.8331 8.33325 15.8331C8.52158 15.8331 8.71075 15.7698 8.86659 15.6398C9.21992 15.3456 9.26825 14.8198 8.97325 14.4664L5.94575 10.8331H15.8333C16.2933 10.8331 16.6666 10.4598 16.6666 9.99976C16.6666 9.53976 16.2933 9.16643 15.8333 9.16643Z"
                />
              </svg>
              <span>Back</span>
            </Link>
          </div>
          <div className="col-md-3">
            <ul className="truckAasanaLink">
              {category &&
                category.map((item, index) => (
                  <li key={index}>
                    <a href={`#tabs-${index}`}>{item}</a>
                  </li>
                ))}
              {eventImages && (
                <li>
                  <a href="#eventsGallery">Event Gallery</a>
                </li>
              )}
              {eventVideos && eventVideos.length > 0 && (
                <li>
                  <a href="#eventsGallery">Event Video</a>
                </li>
              )}
            </ul>
          </div>
          <div className="col-md-9">
            {eventDetailData &&
              eventDetailData.data &&
              eventDetailData.data.map((item, index) => (
                <>
                  <div className="zee-subhead-1" id={`tabs-${index}`} key={index}>
                    {item && item.category.name}
                  </div>
                  {item && parseHtml(item.description)}
                </>
              ))}

            <div className="zmcl-navTabs-2 pt-3" id="eventsGallery">
              <nav>
                <div className="nav nav-tabs" role="tablist">
                  {eventImages && (
                    <button className="nav-link active" id="nav-tab-1" data-bs-toggle="tab" data-bs-target="#nav-target-tab-1" type="button" role="tab" aria-controls="nav-target-tab-1" aria-selected="true">
                      <span>Event Gallery</span>
                    </button>
                  )}
                  {eventVideos && eventVideos.length > 0 && (
                    <button className="nav-link" id="nav-tab-2" data-bs-toggle="tab" data-bs-target="#nav-target-tab-2" type="button" role="tab" aria-controls="nav-target-tab-2" aria-selected="false">
                      <span>Event Videos</span>
                    </button>
                  )}
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-target-tab-1" role="tabpanel" aria-labelledby="nav-tab-1">
                  {eventImages && (
                    <OwlCarousel className="owl-theme life-at-zee owl-bottom-nav" {...lifeAtZee}>
                      {eventImages &&
                        eventImages.map((item, index) => (
                          <div className="item" key={index}>
                            <div className="lifeZeeWrp">
                              <a href={item && IMAGE_BASE_URL + item} data-fancybox="gallery" data-caption={eventDetailData && eventDetailData.eventData && eventDetailData.eventData.event_name}>
                                <img src={item && IMAGE_BASE_URL + item} alt="life-at-zee" />
                              </a>
                            </div>
                          </div>
                        ))}
                    </OwlCarousel>
                  )}
                </div>
                <div className="tab-pane fade" id="nav-target-tab-2" role="tabpanel" aria-labelledby="nav-tab-2">
                  {eventVideos && eventVideos.length > 0 && (
                    <OwlCarousel className="owl-theme life-at-zee owl-bottom-nav" {...lifeAtZee}>
                      {eventVideos &&
                        eventVideos.map((item, index) => (
                          <div className="item" key={index}>
                            <div className="lifeZeeWrp">
                              <video className="rounded img-fluid" controlsList="nodownload" muted onClick={(e) => handleVideos(item && IMAGE_BASE_URL + item)}>
                                <source src={item && IMAGE_BASE_URL + item} type="video/mp4" />
                              </video>
                            </div>
                          </div>
                        ))}
                    </OwlCarousel>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { EventReducer } = state;
  const { eventDetailData } = EventReducer;

  return {
    eventDetailData: EventReducer.eventDetailData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    eventDetailsApi: (data) => dispatch(eventDetailsApi(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
