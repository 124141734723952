import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { EVENT_Details, EVENT_LIST, CORPORATE_PRESENTATION, IMAGE_BASE_URL } from "../constant";


export const eventListApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getEventRequest());
        axios
            .get(EVENT_LIST, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getEventDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}

export const eventDetailsApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getEventRequest());
        axios
            .get(EVENT_Details + '/' + data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getEventDetailsDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}

export const corporateForm = (data) => {
    return (dispatch, getState) => {
        dispatch(getEventRequest());
        axios
            .post(CORPORATE_PRESENTATION, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    const file = response.data.data;
                    // console.log(file.file_name);
                    if (file) {

                        // Creating new object of PDF file
                        // const fileURL = window.URL.createObjectURL(new Blob([response.data.file_name]));
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = IMAGE_BASE_URL + '' + file.file_name;
                        alink.target = '_blank';
                        alink.download = 'download';
                        alink.click();
                    }

                    dispatch(handleSuccess({ message: response.data.message }));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const getEventRequest = data => {
    return {
        type: "EVENT_Data_REQUESTED",
    };
};

export const getEventDataRespond = data => {
    return {
        type: "Event_DATA_RESPONSE",
        data: data,
    };
}

export const getEventDetailsDataRespond = data => {
    return {
        type: "Event_Details_DATA_RESPONSE",
        data: data,
    };
}
export const handleSuccess = (data) => {
    return {
        type: "SHOW_TOAST",
        data,
        toastType: "success",
    };
};

export const handleError = error => {
    return {
        type: "EVENT_ERROR",
        error,
    };
};