import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { ZEE_LIFE_VIDEO } from "../constant";


export const careerZeeLifeApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getCareerRequest());
        axios
            .get(ZEE_LIFE_VIDEO, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getCareerDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}


export const getCareerRequest = data => {
    return {
        type: "CAREER_Data_REQUESTED",
    };
};
export const getCareerDataRespond = data => {
    return {
        type: "CAREER_DATA_RESPONSE",
        data: data,
    };
}

export const handleError = error => {
    return {
        type: "CAREER_ERROR",
        error,
    };
};