const initState = {
    careerData: [],
    error: null
};

const CareerReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "CAREER_Data_REQUESTED":
            return {
                ...state,
            };
        case "CAREER_DATA_RESPONSE":
            return {
                ...state,
                careerData: data,
            };
        case "CAREER_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }

}
export default CareerReducer;