import React from "react";
import { IMAGE_BASE_URL } from "../../../redux/constant";

const HighAlacarte = ({ alacarteHigh }) => {
    return (
        alacarteHigh && alacarteHigh.length > 0 && alacarteHigh.map((item, index) =>
            <div className="col-md-3" key={index}>
                <div className="chnlPricing">
                    <img src={item && IMAGE_BASE_URL + '' + item.channel_logo} alt="channel logo" />
                    <div className="chnlData">
                        <span>{item && item.channel_name}</span>
                        <b>{item && item.channel_price}</b>
                    </div>
                </div>
            </div>
        )
    );
}

export default HighAlacarte;