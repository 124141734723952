const initState = {
    eventData: [],
    eventDetailData: [],
    error: null
};

const EventReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "EVENT_Data_REQUESTED":
            return {
                ...state,
            };
        case "Event_DATA_RESPONSE":
            return {
                ...state,
                eventData: data,
            };
        case "Event_Details_DATA_RESPONSE":
            return {
                ...state,
                eventDetailData: data,
            };
        case "EVENT_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }

}
export default EventReducer;