import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { companyPolicyApi } from "../../../redux/action/privacy";
import { parseHtml } from "../../../Utils/utils";
import { Tooltip } from "antd";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import PDFModal from "./pdfModal";


const CompanyPolicy = ({ companyPolicyApi, companyPolicyData }) => {
  const [modal, setModal] = useState(false);
  const [pdfFile, setPdfFile] = useState();
  useEffect(() => {
    companyPolicyApi();
  }, []);
  const companyData = companyPolicyData && companyPolicyData.data && companyPolicyData.data.file_name;
  const companyParseData = companyData && JSON.parse(companyData);
  const handlePdfFile = (file) => {
    alert(file)
    setPdfFile(file);
    setModal(true);
  }
  return (
    <>
      <section className="hero-banner-page cms-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="zee-heading-2">Company Policy</div>
              {/* <div className="hero-banner-para-1">Effective Date {companyPolicyData && companyPolicyData.data && new Date(companyPolicyData.data.updated_at).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        {/* <div className="row"> */}
        <div className="col-md-7 mx-auto my-md-5 pt-4">
          <div className="row g-4 mt-4">


            {companyParseData && companyParseData.map((item, index) =>
            (
              <div className="col-md-6" key={index}>
                <div className="download-attach" onClick={() => handlePdfFile(item)}>
                  <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                  <div className="attchmntData">
                    <Tooltip placement="top" title={`${item}`}>
                      <b>
                        {item}
                      </b>
                    </Tooltip>

                  </div>
                  <img src={require("../static/img/icons/ViewIcon.svg").default} className="downloadIcon" alt="download" />
                </div>
              </div>
            )
            )}
          </div>
        </div>
      </div>
      {modal &&
        <PDFModal show={modal} onCancel={() => setModal(false)} pdfFile={pdfFile} />
      }
    </>
  );
};

const mapStateToProps = (state) => {
  const { PrivacyReducer } = state;
  const { companyPolicyData } = PrivacyReducer;

  return {
    companyPolicyData: PrivacyReducer.companyPolicyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    companyPolicyApi: () => dispatch(companyPolicyApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompanyPolicy);
