const initState = {
    contactUsData: [],
    error: null
};

const ContactUsReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "CONTACTUS_Data_REQUESTED":
            return {
                ...state,
            };
        case "CONTACTUS_DATA_RESPONSE":
            return {
                ...state,
                contactUsData: data,
            };
        case "CONTACTUS_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }

}
export default ContactUsReducer;