export const WebRoutes = {
  HOME: "/",
  ABOUT: "/about",
  CHANNEL: "/channels",
  DIGITAL: "/digital",
  BROADCAST: "/broadcast",
  CHANNELPRICING: "/channel-pricing",
  MRPAGREEMENTS: "/mrp-agreements",
  PRODUCT: "/products",
  INVESTERINFO: "/investorinfo",
  CAREER: "/career",
  ADVERTISEWITHUS: "/AdvertiseWithUs",
  CONTACTUS: "/contact-us",
  PRIVACYPOLICY: "/privacy-policy",
  TERMSCONDITIONS: "/terms-conditions",
  EVENTDETAILS: "/event-detail",
  COMPANYPOLICY: "/company-policies",
};
