import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { INVESTERS, INVESTER_FILTER } from "../constant";


export const investerDataApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getInvesterRequest());
        axios
            .get(INVESTERS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getInvesterDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}


export const investerSearchDataApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getInvesterRequest());
        axios
            .post(INVESTER_FILTER, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getInvesterSearchDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}


export const getInvesterRequest = data => {
    return {
        type: "INVESTER_Data_REQUESTED",
    };
};
export const getInvesterDataRespond = data => {
    return {
        type: "INVESTER_DATA_RESPONSE",
        data: data,
    };
}

export const getInvesterSearchDataRespond = data => {
    return {
        type: "INVESTER_SEARCH_DATA_RESPONSE",
        data: data,
    };
}

export const handleError = error => {
    return {
        type: "INVESTER_ERROR",
        error,
    };
};