import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { BROADCAST_URL, CHANNEL_DIGITAL, CHANNEL_EDITOR, CHANNEL_OVERVIEW, CHANNEL_PRODUCT } from "../constant";

export const channelEditorApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getChannelRequest());
    axios
      .get(CHANNEL_EDITOR, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getChannelEditorDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const channelOverViewApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getChannelRequest());
    axios
      .get(CHANNEL_OVERVIEW, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getChannelOverViewDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const channelDigitalApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getChannelRequest());
    axios
      .get(CHANNEL_DIGITAL, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getChannelDigitalDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const channelBroadcastApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getChannelRequest());
    axios
      .get(BROADCAST_URL, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getChannelBroadcastDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const channelProductApi = (data) => {
  return (dispatch, getState) => {
    dispatch(getChannelRequest());
    axios
      .get(CHANNEL_PRODUCT, {
        headers: {
          ...getCommonApiHeader(),
        },
      })
      .then((response) => {
        dispatch(getChannelProductDataRespond(response?.data));
      })
      .catch((err) => {
        dispatch(handleError(err));
      });
  };
};

export const getChannelRequest = (data) => {
  return {
    type: "CHANNEL_Data_REQUESTED",
  };
};

export const getChannelEditorDataRespond = (data) => {
  return {
    type: "CHANNEL_EDITOR_DATA_RESPONSE",
    data: data,
  };
};

export const getChannelOverViewDataRespond = (data) => {
  return {
    type: "CHANNEL_Overview_DATA_RESPONSE",
    data: data,
  };
};

export const getChannelProductDataRespond = (data) => {
  return {
    type: "CHANNEL_Product_DATA_RESPONSE",
    data: data,
  };
};

export const getChannelDigitalDataRespond = (data) => {
  return {
    type: "CHANNEL_Digital_DATA_RESPONSE",
    data: data,
  };
};

export const getChannelBroadcastDataRespond = (data) => {
  return {
    type: "CHANNEL_BROADCAST_DATA_RESPONSE",
    data: data,
  };
};

export const handleError = (error) => {
  return {
    type: "CHANNEL_ERROR",
    error,
  };
};
