import { combineReducers } from "redux";
import AboutReducer from "./AboutReducer";
import CareerReducer from "./CareerReducer";
import ChannelReducer from "./ChannelReducer";
import CommonReducer from "./CommonReducer";
import ContactUsReducer from "./ContactUsReducer";
import EventReducer from "./EventReducer";
import HomeReducer from "./HomeReducer";
import InvesterReducer from "./InvesterReducer";
import PrivacyReducer from "./PrivacyReducer";

const userReducers = {
    HomeReducer,
    PrivacyReducer,
    ChannelReducer,
    EventReducer,
    CareerReducer,
    ContactUsReducer,
    CommonReducer,
    InvesterReducer,
    AboutReducer
}


const rootReducer = combineReducers({
    ...userReducers
});

export default rootReducer;