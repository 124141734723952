const initState = {
    networData: [],
    smarterData: [],
    matricsData: [],
    channelAppsData: [],
    metaTagData: [],
    pageBlockData: [],
    error: null
};
const HomeReducer = (state = initState, action) => {

    const data = action?.data;
    switch (action?.type) {
        case "HOME_Data_REQUESTED":
            return {
                ...state,
            };
        case "NETWORK_DATA_RESPONSE":
            return {
                ...state,
                networData: data,
            };
        case "SMARTER_DATA_RESPONSE":
            return {
                ...state,
                smarterData: data,
            };
        case "Matrics_DATA_RESPONSE":
            return {
                ...state,
                matricsData: data,
            };
        case "CHANNEL_APPS_DATA_RESPONSE":
            return {
                ...state,
                channelAppsData: data,
            };
        case "META_TAG_DATA_RESPONSE":
            return {
                ...state,
                metaTagData: data,
            };
        case "PAGE_BLOCK_DATA_RESPONSE":
            return {
                ...state,
                pageBlockData: data,
            };
        case "HOME_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}

export default HomeReducer;