// export const BASE_URL = "http://127.0.0.1:8000/api";
// export const IMAGE_BASE_URL = "http://127.0.0.1:8000/storage/";

// export const BASE_URL = "http://13.232.230.62/zmcl-admin/public/api"; // Staging URL
// export const IMAGE_BASE_URL = "http://13.232.230.62/zmcl-admin/public/storage/"; // Staging URL
//http://admin.zeemedia.in/api/smarters-list
//export const BASE_URL = "https://zeemedia.in/zmcl-admin/public/api"; // Production URL
//export const IMAGE_BASE_URL = "https://zeemedia.in/zmcl-admin/public/storage/"; // Production URL

export const BASE_URL = "https://admin.zeemedia.in/api"; // New Production URL
export const IMAGE_BASE_URL = "https://admin.zeemedia.in/storage/"; // New Production URL

export const NETWORK_DATA = `${BASE_URL}/networkdata-list`;

export const SMARTERS = `${BASE_URL}/smarters-list`;

export const KEY_MATRICS = `${BASE_URL}/matrics-list`;

export const PRIVACY_POLICYS = `${BASE_URL}/privacy-policy`;

export const TERMS_CONDITIONS = `${BASE_URL}/terms-conditions`;

export const SOCIAL_MEDIA = `${BASE_URL}/social-media`;

export const CHANNEL_EDITOR = `${BASE_URL}/channel-editor`;

export const CHANNEL_OVERVIEW = `${BASE_URL}/channel-overview`;

export const CHANNEL_DIGITAL = `${BASE_URL}/channel-digital`;

export const CHANNEL_PRODUCT = `${BASE_URL}/channel-product`;

export const EVENT_LIST = `${BASE_URL}/event-list`;

export const EVENT_Details = `${BASE_URL}/event-details`;

// Corporate post fom api
export const CORPORATE_PRESENTATION = `${BASE_URL}/corporate-fom`;

// Zee life and video fom api
export const ZEE_LIFE_VIDEO = `${BASE_URL}/zee-life`;

// Contact us fom api
export const CONTACT_US = `${BASE_URL}/contact-us`;

// Contact us fom api
export const CONTACT_QUERY = `${BASE_URL}/contact-query`;

// Invester data api
export const INVESTERS = `${BASE_URL}/investors`;

// Invester data api
export const ABOUTUS = `${BASE_URL}/about-us`;

// Channels Apps
export const CHANNEL_APPS = `${BASE_URL}/channel-apps`;

// Invester Filter
export const INVESTER_FILTER = `${BASE_URL}/invester-search`;

// Meta tags
export const META_TAG_URL = `${BASE_URL}/meta-tags`;

// Page Blocks
export const PAGE_BLOCKS_URL = `${BASE_URL}/page-block`;

// Broadcast Blocks
export const BROADCAST_URL = `${BASE_URL}/broad-cast`;

// Company Policy
export const COMPANY_POLICY_URL = `${BASE_URL}/comapny-policy`;
