import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { careerZeeLifeApi } from "../../../redux/action/career";
import { IMAGE_BASE_URL } from "../../../redux/constant";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const Career = ({ careerZeeLifeApi, careerData }) => {
  useEffect(() => {
    careerZeeLifeApi();
  }, []);

  const [zeeImage, setZeeImage] = useState([]);
  const [status, setStatus] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [yearSearch, setYearSearch] = useState("Year");

  // const careerCarousal = {
  //   loop: false,
  //   dots: false,
  //   lazyLoad: true,
  //   margin: 20,
  //   navText: [
  //     '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M11.1352 14.6333C10.9169 14.6333 10.6994 14.5483 10.536 14.3792L7.31686 11.0458C7.00186 10.7183 7.00603 10.1992 7.3277 9.87751L10.661 6.54418C10.986 6.21835 11.5135 6.21835 11.8394 6.54418C12.1644 6.87001 12.1644 7.39668 11.8394 7.72251L9.0852 10.4767L11.7344 13.2208C12.0544 13.5525 12.0452 14.08 11.7144 14.3992C11.5519 14.5558 11.3435 14.6333 11.1352 14.6333"/></svg></svg></span>',
  //     '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M8.75005 14.6335C8.53671 14.6335 8.32338 14.5518 8.16088 14.3893C7.83505 14.0635 7.83505 13.5368 8.16088 13.211L10.915 10.4568L8.26505 7.71267C7.94588 7.38101 7.95505 6.85351 8.28588 6.53434C8.61755 6.21517 9.14505 6.22434 9.46421 6.55434L12.6825 9.88767C12.9984 10.2152 12.9942 10.7343 12.6725 11.056L9.33921 14.3893C9.17671 14.5518 8.96338 14.6335 8.75005 14.6335"/></svg></span>',
  //   ],
  //   nav: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 2,
  //     },
  //     1000: {
  //       items: 3,
  //     },
  //   },
  // };

  // const zeeLife = careerData && careerData.data && [...new Set(careerData.data.map((q) => q.image))];
  const videoData = careerData && careerData.video;
  const galleryType = careerData && careerData.gallery_type;
  const defaultGallery = careerData && careerData.defaultGallery;
  const years = careerData && careerData.data && [...new Set(careerData.data.map((q) => q.years))];

  // console.log(galleryType);

  // useEffect(() =>{

  // },[zeeImage])

  const handleGalleryChange = (id) => {
    setActiveTab(id);
    const zee = careerData && careerData.data && careerData.data.filter((q) => q.gallery_id == id);
    setZeeImage(zee);
    setStatus(false);
  };

  const handlesearch = (event) => {
    if (event !== "Year") {
      setYearSearch(event);
      const zee = careerData && careerData.data && careerData.data.filter((q) => q.years == event);
      setZeeImage(zee);
      setStatus(false);
    } else {
    }
  };

  return (
    <>
      <section className="hero-banner-page careers-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="zee-heading-2">Join The Team</div>
              <div className="hero-banner-para-1">Start Your Career Journey with Zee Media with just a click of a button. Be The Part Of India’s Biggest Infotainment Network</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container my-0 my-md-5 py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="video-frame">
              <video key={videoData && IMAGE_BASE_URL + "" + videoData.video} autoPlay={true} className="actual-video" controls={true} controlsList="nodownload">
                <source src={videoData && IMAGE_BASE_URL + "" + videoData.video} type="video/mp4" />
              </video>
              {/* <img src={require("../static/img/icons/play-btn.svg").default} alt="play-btn" className="play-btn" /> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container pb-5">
        <div className="row">
          <div className="col-md-7 mx-auto text-center">
            <div className="zee-heading-1">Employee Benefits & Perks</div>
            <div className="zee-para-1 justify-content-center">ZMCL is proud to provide ample benefits and programs that support our diverse employees in their personal lives.</div>
          </div>
        </div>
        <div className="row g-4 my-4">
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Flexible-Working.svg").default} alt="Employee-Benefits" />
              <b>Flexible Working</b>
              <span>Hybrid work model that flexes with employee’s weekly schedule and other programs that provide schedule assistance.</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Vacation-Policy.svg").default} alt="Employee-Benefits" />
              <b>Leave Policy</b>
              <span>Generous paid leave for employees at all stages of life: maternity & paternity leave, caregiver leave, and sick leave. </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Wellness-Fitness-Centres.svg").default} alt="Employee-Benefits" />
              <b>Wellness and Fitness Centres</b>
              <span>Monthly intellectual wellbeing workshops. Access to fitness programs like daily yoga sessions; in-house gym facilities.</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Comprehensive-Health-Plans.svg").default} alt="Employee-Benefits" />
              <b>Comprehensive Health Plans</b>
              <span>Premium coverage to employees through health, dental, and vision plans. Family Health benefits are available, as are parental medical insurance.</span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Educational-Support.svg").default} alt="Employee-Benefits" />
              <b>Educational Support</b>
              <span>Access to online courses for career upscaling. Knowledge sharing “Interact with Experts” workshops with industry thought leaders every month. In-house library facility. </span>
            </div>
          </div>
          <div className="col-md-4">
            <div className="employeeBnfts">
              <img src={require("../static/img/icons/Children-Day-Care-Centre.svg").default} alt="Employee-Benefits" />
              <b>Children Day Care Centre</b>
              <span>To ease the stress and worry of working parents. We have our own on-site child care Facility with Kidzee where kids can learn and grow. Service available in selected offices.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="openPositions">
          <div className="row">
            <div className="col-md-8">
              <div className="zee-heading-1">Open Positions</div>
              <div className="zee-para-1">Zee Media Corporation Limited values and take pride in offering great candidate experience to everyone looking for a career opportunity. We welcome and inspire to bring your best self to every opportunity and develop your career in a resilient and dynamic industry. Together, we proudly live and work by our values, striving each day to be better and do better as individuals, as a company and members of the communities. Come have a seat at our table.</div>
              <a href="https://zeemedia.darwinbox.in/ms/candidate/careers" rel="noreferer" target="_blank" className="zee-btn-3">
                <span>View Openings</span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-4 mb-5 pb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="zee-heading-1">Life at Zee</div>
            <div className="zee-para-1">We believe that an inclusive culture, one that values the diversity and unique perspective of each individual, allows us to bring our best selves to work and leads to greater teamwork, creativity, and trust.</div>
            <div className="zee-para-1">Our employees cherish and enjoy festivals and entertainment activities together. The engagement activities do not limit to celebration and events for families, we have various other initiatives which focuses on other factors of employee engagement such as rewards and recognition, employee well-being initiatives, employee benefits, the scope for innovation, etc.</div>
          </div>
          <div className="col-md-12">
            <div className="row my-4">
              <div className="col-md-10 d-flex align-items-center">
                <ul className="galleryTabs">
                  {galleryType &&
                    galleryType.map((item) => (
                      <li className="item" key={item && item.id}>
                        <span className={`pill-tab ${item && item.id === activeTab ? "active" : ""}`} onClick={(e) => handleGalleryChange(item && item.id)}>
                          {item && item.name}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
              <div className="col-md-2">
                <div className="zee-form m-0">
                  <select className="form-control" value={yearSearch} onChange={(e) => handlesearch(e.target.value)}>
                    <option value="Year" defaultValue readOnly hidden disabled>
                      Year
                    </option>
                    {years &&
                      years.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row g-4">
              {
                status
                  ? defaultGallery && (
                      // <OwlCarousel className="life-at-zee owl-bottom-nav" {...careerCarousal}>
                      <>
                        {defaultGallery &&
                          defaultGallery.map((item, index) => (
                            <div className="col-md-3" key={index}>
                              <div className="lifeZeeWrp">
                                <a href={IMAGE_BASE_URL + "" + item.image} data-fancybox="gallery">
                                  <img src={IMAGE_BASE_URL + "" + item.image} alt="life-at-zee" loading="lazy" />
                                </a>
                              </div>
                            </div>
                          ))}
                      </>
                      // </OwlCarousel>
                    )
                  : zeeImage &&
                    // <OwlCarousel className="life-at-zee owl-bottom-nav" {...careerCarousal}>
                    zeeImage &&
                    zeeImage.map((item, index) => (
                      <div className="col-md-3" key={index}>
                        <div className="lifeZeeWrp">
                          <a href={IMAGE_BASE_URL + "" + item.image} data-fancybox="gallery">
                            <img src={IMAGE_BASE_URL + "" + item.image} alt="life-at-zee" loading="lazy" />
                          </a>
                        </div>
                      </div>
                    ))
                // </OwlCarousel>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { CareerReducer } = state;
  const { careerData } = CareerReducer;

  return {
    careerData: CareerReducer.careerData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    careerZeeLifeApi: () => dispatch(careerZeeLifeApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Career);
