import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { pageBlocksApi } from "../../../redux/action/home";
import { WebRoutes } from "../../../routes";

const Header = ({ pageBlocksApi, pageBlockData }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 800) {
      setVisible(false);
    }
  };

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const handleNavToggle = () => {
    if (window.innerWidth > 990) return false;
    document.querySelector("#navbar").classList.toggle("navbar-mobile");
    document.querySelector(".mobile-nav-toggle").classList.toggle("flaticon-menu");
    document.querySelector(".mobile-nav-toggle").classList.toggle("flaticon-close");
  };

  const handleIconToggle = () => {
    document.querySelector(".mobile-nav-toggle").classList.toggle("flaticon-menu");
    document.querySelector(".mobile-nav-toggle").classList.toggle("flaticon-close");
    document.querySelector("#navbar").classList.toggle("navbar-mobile");
  };

  const location = useLocation();
  const [home, setHome] = useState(true);
  const [career, setCareer] = useState(true);
  const [about, setAbout] = useState(true);
  const [contact, setContact] = useState(true);
  const [digital, setDigital] = useState(true);
  const [broadcast, setBroadcast] = useState(true);
  const [channel, setChannel] = useState(true);
  const [investerInfo, setInvesterInfo] = useState(true);
  const [advertise, setAdvertise] = useState(true);
  const [privacy, setPrivacy] = useState(true);
  const [terms, setTerms] = useState(true);
  const [eventDetails, setEventDetails] = useState(true);

  useEffect(() => {
    handleChange();
  }, [pageBlockData]);
  const handleChange = () => {
    pageBlockData &&
      pageBlockData.data &&
      pageBlockData.data.map((item) => {
        if (item.page_name == WebRoutes.DIGITAL) {
        }
        if (item.page_name == WebRoutes.CAREER && item.status == 1) {
          setCareer(false);
        }
        if (item.page_name == WebRoutes.HOME && item.status == 1) {
          setHome(false);
        }
        if (item.page_name == WebRoutes.ABOUT && item.status == 1) {
          setAbout(false);
        }
        if (item.page_name == WebRoutes.CONTACTUS && item.status == 1) {
          setContact(false);
        }
        if (item.page_name == WebRoutes.DIGITAL && item.status == 1) {
          setDigital(false);
        }
        if (item.page_name == WebRoutes.BROADCAST && item.status == 1) {
          setBroadcast(false);
        }
        if (item.page_name == WebRoutes.CHANNEL && item.status == 1) {
          setChannel(false);
        }
        if (item.page_name == WebRoutes.INVESTERINFO && item.status == 1) {
          setInvesterInfo(false);
        }
        if (item.page_name == WebRoutes.ADVERTISEWITHUS && item.status == 1) {
          setAdvertise(false);
        }
        if (item.page_name == WebRoutes.PRIVACYPOLICY && item.status == 1) {
          setPrivacy(false);
        }
        if (item.page_name == WebRoutes.TERMSCONDITIONS && item.status == 1) {
          setTerms(false);
        }
        if (item.page_name == WebRoutes.EVENTDETAILS && item.status == 1) {
          setEventDetails(false);
        }
      });
  };

  // useEffect(() => {
  //   pageBlocksApi();
  // }, [location]);

  // useEffect(() => {
  //   pageBlockData &&
  //     pageBlockData.data &&
  //     pageBlockData.data.map((item) => {
  //       if (item.page_name == WebRoutes.CAREER && item.status == 1) {
  //         setCareer(false);
  //       }
  //       if (item.page_name == WebRoutes.HOME && item.status == 1) {
  //         setHome(false);
  //       }
  //       if (item.page_name == WebRoutes.ABOUT && item.status == 1) {
  //         setAbout(false);
  //       }
  //       if (item.page_name == WebRoutes.CONTACTUS && item.status == 1) {
  //         setContact(false);
  //       }
  //       if (item.page_name == WebRoutes.DIGITAL && item.status == 1) {
  //         setDigital(false);
  //       }
  //       if (item.page_name == WebRoutes.CHANNEL && item.status == 1) {
  //         setChannel(false);
  //       }
  //       // handleChannel();
  //       if (item.page_name == WebRoutes.INVESTERINFO && item.status == 1) {
  //         setInvesterInfo(false);
  //       }
  //       if (item.page_name == WebRoutes.ADVERTISEWITHUS && item.status == 1) {
  //         setAdvertise(false);
  //       }
  //       if (item.page_name == WebRoutes.PRIVACYPOLICY && item.status == 1) {
  //         setPrivacy(false);
  //       }
  //       if (item.page_name == WebRoutes.TERMSCONDITIONS && item.status == 1) {
  //         setTerms(false);
  //       }
  //       if (item.page_name == WebRoutes.EVENTDETAILS && item.status == 1) {
  //         setEventDetails(false);
  //       }
  //     });
  // }, [location])

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">
          {home ? (
            <Link to={WebRoutes.HOME} className="logo me-auto">
              <img src={require("../static/img/logo.svg").default} alt="ZeeMedia-Logo" />
            </Link>
          ) : null}

          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul>
              <li>
                {about ? (
                  <Link className="nav-link" to={WebRoutes.ABOUT} onClick={() => handleNavToggle()}>
                    About Us
                  </Link>
                ) : null}
              </li>

              {window.innerWidth > 990 ? (
                <li className="dropdown">
                  <a href="https://zeemedia.in/" onClick={(e) => e.preventDefault()}>
                    <span>Products</span> <i className="flaticon-down-arrow"></i>
                  </a>
                  <ul>
                    {channel ? (
                      <li>
                        <Link to={WebRoutes.CHANNEL}>Linear</Link>
                      </li>
                    ) : null}
                    {digital ? (
                      <li>
                        <Link to={WebRoutes.DIGITAL}>Digital</Link>
                      </li>
                    ) : null}
                    {broadcast ? (
                      <li>
                        <Link to={WebRoutes.BROADCAST}>Broadcast</Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : (
                <>
                  {channel ? (
                    <li>
                      <Link to={WebRoutes.CHANNEL} onClick={() => handleNavToggle()}>
                        Linear
                      </Link>
                    </li>
                  ) : null}
                  {digital ? (
                    <li>
                      <Link to={WebRoutes.DIGITAL} onClick={() => handleNavToggle()}>
                        Digital
                      </Link>
                    </li>
                  ) : null}
                  {broadcast ? (
                    <li>
                      <Link to={WebRoutes.BROADCAST} onClick={() => handleNavToggle()}>
                        Broadcast
                      </Link>
                    </li>
                  ) : null}

                  {/* <li>
                    <Link to={WebRoutes.PRODUCT} onClick={() => handleNavToggle()}>
                      Products
                    </Link>
                  </li> */}
                </>
              )}

              {investerInfo ? (
                <li>
                  <Link className="nav-link" to={WebRoutes.INVESTERINFO} onClick={() => handleNavToggle()}>
                    Investor Info
                  </Link>
                </li>
              ) : null}
              {career ? (
                <li>
                  <Link className="nav-link" to={WebRoutes.CAREER} onClick={() => handleNavToggle()}>
                    Careers
                  </Link>
                </li>
              ) : null}
              {advertise ? (
                <li>
                  <Link className="nav-link" to={WebRoutes.ADVERTISEWITHUS} onClick={() => handleNavToggle()}>
                    Advertise with us
                  </Link>
                </li>
              ) : null}
              {contact ? (
                <li>
                  <Link className="nav-link" to={WebRoutes.CONTACTUS} onClick={() => handleNavToggle()}>
                    Contact Us
                  </Link>
                </li>
              ) : null}
            </ul>
            <i
              className="flaticon-menu mobile-nav-toggle"
              onClick={() => {
                handleIconToggle();
              }}
            ></i>
          </nav>
        </div>
      </header>

      <a href="https://zeemedia.in/" className={`backToTop ${visible ? "animate__animated animate__fadeInUp" : "animate__animated animate__zoomOutDown"}`} onClick={(e) => backToTop(e)}>
        <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M10.5506 14.0064L10.853 17.4289C10.853 17.8013 10.7051 18.1584 10.4418 18.4217C10.1784 18.6851 9.82129 18.833 9.44888 18.833C9.07648 18.833 8.71933 18.6851 8.456 18.4217C8.19268 18.1584 8.04474 17.8013 8.04474 17.4289L8.34721 14.0064C8.35552 13.7198 8.47518 13.4478 8.68079 13.2481C8.8864 13.0484 9.16177 12.9366 9.44842 12.9366C9.73507 12.9366 10.0104 13.0484 10.2161 13.2481C10.4217 13.4478 10.5413 13.7198 10.5496 14.0064" />
          <path d="M10.4953 2.51351C10.5489 2.56716 10.7774 2.76695 10.967 2.95473C12.495 4.55726 13.7522 6.39768 14.6892 8.40388C14.8402 8.7586 14.9447 9.13139 15 9.51295C15 9.8056 14.9306 10.0941 14.7974 10.3547C14.6095 10.6885 14.317 10.9511 13.9649 11.1021C13.6401 11.201 13.31 11.2816 12.9761 11.3435C11.8109 11.5134 10.6339 11.5889 9.4565 11.5692C8.31616 11.5797 7.17642 11.513 6.04511 11.3694C5.62777 11.2966 5.2172 11.1893 4.81765 11.0484C4.54371 10.9091 4.31329 10.6972 4.15149 10.4359C3.98968 10.1746 3.90271 9.87393 3.90005 9.5666L3.90005 9.51295C3.96718 9.08326 4.09422 8.6651 4.27745 8.27068C5.20891 6.31047 6.44734 4.51153 7.94598 2.94178C8.10676 2.77943 8.27794 2.62772 8.45843 2.48761C8.74308 2.27686 9.08846 2.16423 9.44262 2.16663C9.82221 2.1627 10.1924 2.28469 10.4953 2.51351Z" />
        </svg>
      </a>
    </>
  );
};

const mapStateToProps = (state) => {
  const { HomeReducer } = state;
  // const { pageBlockData } = HomeReducer;

  return {
    // pageBlockData: HomeReducer.pageBlockData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // pageBlocksApi: () => dispatch(pageBlocksApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
