import React, { useEffect } from "react";
import { connect } from "react-redux";
import { termsConditionsApi } from "../../../redux/action/privacy";
import { parseHtml } from "../../../Utils/utils";

const TermsConditions = ({ termsConditionsApi, termsData }) => {
  useEffect(() => {
    termsConditionsApi();
  }, []);

  return (
    <>
      <section className="hero-banner-page cms-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="zee-heading-2">Terms & Conditions</div>
              <div className="hero-banner-para-1">Effective Date {termsData && termsData.data && new Date(termsData.data.updated_at).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })} </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto my-md-5 pt-5">
            <div className="TnC-PnP">{termsData && termsData.data && parseHtml(termsData.data.description)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { PrivacyReducer } = state;
  const { termsData } = PrivacyReducer;

  return {
    termsData: PrivacyReducer.termsData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    termsConditionsApi: () => dispatch(termsConditionsApi()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions);
