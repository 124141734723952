import React, { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { WebRoutes } from "./routes";
import BaseDashboard from "./user/component";
import About from "./user/component/about/About";
import Broadcast from "./user/component/broadcast";
import ChannelPricing from "./user/component/broadcast/channel-pricing";
import MRPAgreement from "./user/component/broadcast/mrp-agreement";
import AdvertiseWithUs from "./user/component/advertiseus/advertise";
import EventDetails from "./user/component/advertiseus/eventDetails";
import Career from "./user/component/career/career";
import Channel from "./user/component/channels/channel";
import Digital from "./user/component/channels/digital";
import Product from "./user/component/channels/product";
import ContactUs from "./user/component/contactus/contact";
import Home from "./user/component/home";
import InvesterInfo from "./user/component/invester/invester";
import PrivacyPolicy from "./user/component/policy/privacy_policy";
import TermsConditions from "./user/component/policy/terms_condition";
import ScrollToTop from "./user/component/ScrolTop";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CompanyPolicy from "./user/component/policy/company_policy";

function App() {
  useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    // ========== Easy selector helper function ==========
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };

    // ========== Easy event listener function ==========
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    // ========== Easy on scroll event listener ==========
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    // ========== Scrolls to an element with header offset ==========
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;
      if (!header.classList.contains("header-scrolled")) {
        offset -= 16;
      }
      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    // ========== Toggle .header-scrolled class to #header when page is scrolled ==========
    let selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 75) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    // ========== Mobile nav dropdowns activate ==========
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    // ========== Scroll with ofset on page load with hash links in the url ==========
    window.addEventListener("load", () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });

    // document.querySelector(window).scroll(function () {
    // if (window.scrollY >= 1000) {
    //   $(".backToTop").show().removeClass("zoomOutDown").addClass("fadeInUp");
    // } else {
    //   $(".backToTop").removeClass("fadeInUp").addClass("zoomOutDown");
    // }
    // });
  });

  return (
    <div className="App">
      <ScrollToTop>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense>
                <BaseDashboard />
              </Suspense>
            }
          >
            <Route path={WebRoutes.HOME} element={<Home />} />
            <Route path={WebRoutes.ABOUT} element={<About />} />
            <Route path={WebRoutes.CHANNEL} element={<Channel />} />
            <Route path={WebRoutes.DIGITAL} element={<Digital />} />
            <Route path={WebRoutes.PRODUCT} element={<Product />} />
            <Route path={WebRoutes.BROADCAST} element={<Broadcast />} />
            <Route path={WebRoutes.MRPAGREEMENTS} element={<MRPAgreement />} />
            <Route path={WebRoutes.CHANNELPRICING} element={<ChannelPricing />} />
            <Route path={WebRoutes.INVESTERINFO} element={<InvesterInfo />} />
            <Route path={WebRoutes.CAREER} element={<Career />} />
            <Route path={WebRoutes.ADVERTISEWITHUS} element={<AdvertiseWithUs />} />
            <Route path={WebRoutes.CONTACTUS} element={<ContactUs />} />
            <Route path={WebRoutes.PRIVACYPOLICY} element={<PrivacyPolicy />} />
            <Route path={WebRoutes.TERMSCONDITIONS} element={<TermsConditions />} />
            <Route path={WebRoutes.EVENTDETAILS} element={<EventDetails />} />
            <Route path={WebRoutes.COMPANYPOLICY} element={<CompanyPolicy />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
