import React, { useEffect } from "react";
import { connect } from "react-redux";
import { channelEditorApi, channelOverViewApi } from "../../../redux/action/channel";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { parseHtml } from "../../../Utils/utils";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Channel = ({ channelEditorApi, channelEditorData, channelOverViewApi, channelOverviewData }) => {
  useEffect(() => {
    channelEditorApi();
    channelOverViewApi();
  }, []);

  const channelEditors = {
    loop: true,
    dots: false,
    margin: 10,
    navText: [
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M11.1352 14.6333C10.9169 14.6333 10.6994 14.5483 10.536 14.3792L7.31686 11.0458C7.00186 10.7183 7.00603 10.1992 7.3277 9.87751L10.661 6.54418C10.986 6.21835 11.5135 6.21835 11.8394 6.54418C12.1644 6.87001 12.1644 7.39668 11.8394 7.72251L9.0852 10.4767L11.7344 13.2208C12.0544 13.5525 12.0452 14.08 11.7144 14.3992C11.5519 14.5558 11.3435 14.6333 11.1352 14.6333"/></svg></svg></span>',
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M8.75005 14.6335C8.53671 14.6335 8.32338 14.5518 8.16088 14.3893C7.83505 14.0635 7.83505 13.5368 8.16088 13.211L10.915 10.4568L8.26505 7.71267C7.94588 7.38101 7.95505 6.85351 8.28588 6.53434C8.61755 6.21517 9.14505 6.22434 9.46421 6.55434L12.6825 9.88767C12.9984 10.2152 12.9942 10.7343 12.6725 11.056L9.33921 14.3893C9.17671 14.5518 8.96338 14.6335 8.75005 14.6335"/></svg></span>',
    ],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="hero-banner-page channels-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center">
              <div className="zee-heading-2">Our Channels</div>
              <div className="hero-banner-para-1">Our network's strength lies in the breadth of our iconic brand portfolio, diversified audiences reach, and impactful journalism</div>
              <div className="hero-banner-para-1 mt-2">We Are Powered By Our Diversity</div>
            </div>
          </div>
        </div>
      </section>

      <section className="container my-5 pt-5">
        {channelOverviewData.data &&
          channelOverviewData.data.map((item, index) => (
            <div className="row g-4 mb-5" key={index}>
              <div className="col-md-12">
                <div className="channel-title">{item && item[index] && item[index].category.name}</div>
              </div>
              {item &&
                item.map((record, index) => (
                  <div className="col-lg-4 col-md-4 col-sm-12" key={index}>
                    <div className={`channel-card ${record.theme && record.theme.color_class}`}>
                      <img src={IMAGE_BASE_URL + "" + record.logo} alt="Channel-Logo" className="channel-logo" />
                      <p>{parseHtml(record.description)}</p>
                      {/* <div className="store-rating">
                        <a className="web-store" href={record.play_store_link} target="_blank" rel="noreferrer">
                          <img src={require("../static/img/icons/play-store.svg").default} alt="play-store" />
                          <div>
                            <span>Play Store</span>
                            <b>{record.play_store_value}</b>
                          </div>
                        </a>
                        <a className="web-store" href={record.app_store_link} target="_blank" rel="noreferrer">
                          <img src={require("../static/img/icons/app-store.svg").default} alt="app-store" />
                          <div>
                            <span>App Store</span>
                            <b>{record.app_store_value}</b>
                          </div>
                        </a>
                      </div> */}
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </section>

      <div className="container py-5 my-0 my-md-5">
        <div className="row">
          <div className="col-md-12">
            <div className="zee-heading-1 mb-4">Channel Editors</div>
          </div>
          <div className="col-md-12">
            {channelEditorData && channelEditorData.data && (
              <OwlCarousel className="owl-theme channel-editors owl-bottom-nav" {...channelEditors}>
                {channelEditorData &&
                  channelEditorData.data &&
                  channelEditorData.data.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="chnlEdtr-wrp">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="chnlEdtr-details">
                              <img src={item && IMAGE_BASE_URL + "" + item.editor_image} alt="channel-editor" />
                              <div className="editor-name">{item && item.editor_name}</div>
                              <div className="editor-title">{item && item.editor_designation}</div>
                              <ul>
                                <li>
                                  <a href={item && item.editor_linkedin_link} rel="noreferrer" target="_blank">
                                    <img src={require("../static/img/icons/social-linkedin.svg").default} alt="social-linkedin" />
                                  </a>
                                </li>
                                <li>
                                  <a href={item && item.editor_twitter_link} rel="noreferrer" target="_blank">
                                    <img src={require("../static/img/icons/social-twitter.svg").default} alt="social-twitter" style={{ width: "30% !important" }} />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          {/* <div className="col-md-8">
                            <div className="chnlEdtr-content">
                              <img src={item && IMAGE_BASE_URL + "" + item.channel_logo} alt="channel-log" />
                              <p>{item && parseHtml(item.description)}</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ChannelReducer } = state;
  const { channelEditorData, channelOverviewData } = ChannelReducer;

  return {
    channelEditorData: ChannelReducer.channelEditorData,
    channelOverviewData: ChannelReducer.channelOverviewData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    channelEditorApi: () => dispatch(channelEditorApi()),
    channelOverViewApi: () => dispatch(channelOverViewApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Channel);
