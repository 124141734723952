import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { corporateForm, eventListApi } from "../../../redux/action/event";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { WebRoutes } from "../../../routes";
import Corporate from "../modal/Corporate";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const AdvertiseWithUs = ({ corporateForm, eventListApi, eventData }) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    eventListApi();
  }, []);

  const handleStorage = (id) => {
    localStorage.setItem("eventID", id);
  };

  const lifeAtZee = {
    loop: false,
    dots: false,
    margin: 30,
    navText: [
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M11.1352 14.6333C10.9169 14.6333 10.6994 14.5483 10.536 14.3792L7.31686 11.0458C7.00186 10.7183 7.00603 10.1992 7.3277 9.87751L10.661 6.54418C10.986 6.21835 11.5135 6.21835 11.8394 6.54418C12.1644 6.87001 12.1644 7.39668 11.8394 7.72251L9.0852 10.4767L11.7344 13.2208C12.0544 13.5525 12.0452 14.08 11.7144 14.3992C11.5519 14.5558 11.3435 14.6333 11.1352 14.6333"/></svg></svg></span>',
      '<span><svg width="20" height="21" viewBox="0 0 20 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clip-rule="evenodd" d="M8.75005 14.6335C8.53671 14.6335 8.32338 14.5518 8.16088 14.3893C7.83505 14.0635 7.83505 13.5368 8.16088 13.211L10.915 10.4568L8.26505 7.71267C7.94588 7.38101 7.95505 6.85351 8.28588 6.53434C8.61755 6.21517 9.14505 6.22434 9.46421 6.55434L12.6825 9.88767C12.9984 10.2152 12.9942 10.7343 12.6725 11.056L9.33921 14.3893C9.17671 14.5518 8.96338 14.6335 8.75005 14.6335"/></svg></span>',
    ],
    nav: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  const handleCorporate = (data) => {
    corporateForm(data);
    setOpenModal(false);
  };

  return (
    <>
      <section className="hero-banner-page advertise-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto text-center">
              <div className="zee-heading-2">
                <span>Our Advertising &</span>
                <span>Sponsored Content Solutions</span>
              </div>
              <div className="hero-banner-para-2 mx-auto justify-content-center">We are here to help you successfully reach our unique audience present across our digital properties.</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="advrtContWrp">
              <div className="advt-img">
                <img src={require("../static/img/Native-Advertising.png")} alt="advt-img" />
              </div>
              <div className="advt-cntnt">
                <div className="zee-heading-1">Native Advertising Solutions</div>
                <div className="zee-para-1">Our native advertising solution will help your brand reach the targeted audience. We help brands and advertisers engage with the user base by following best of brand safety guidelines, and publishing in a more contextually relevant ecosystem.</div>
                <ul>
                  <li>Branded Videos</li>
                  <li>Branded Articles</li>
                  <li>Influencer-led activity</li>
                </ul>
              </div>
            </div>
            <div className="advrtContWrp mid">
              <div className="advt-cntnt">
                <div className="zee-heading-1">Banners, Video and Interactive Solutions</div>
                <div className="zee-para-1">Enhance your campaign and increase the longevity of your products and services exposure. Our digital presence is spread across 32 most respected online news and information websites, which register millions of pageviews every month. Our expert team will help your brand explore innovative display advertising solutions across digital, mobile & social platforms in the form of interactive ads:</div>
                <ul>
                  <li>Display Banners</li>
                  <li>Video Inventory on LIVE Tv and VOD</li>
                </ul>
              </div>
              <div className="advt-img">
                <img src={require("../static/img/Interactive-Solutions.png")} alt="advt-img" />
              </div>
            </div>
            <div className="advrtContWrp">
              <div className="advt-img">
                <img src={require("../static/img/Sponsorship.png")} alt="advt-img" />
              </div>
              <div className="advt-cntnt">
                <div className="zee-heading-1">Sponsorship</div>
                <div className="zee-para-1">Our bouquet of channels and digital platforms host some of the most exclusive events throughout the year. Our sponsorship marketing team is expert at matching the right partner to the right event in order to deliver best brand visibility. Spreading your brand across multiple events and channels will allow your brand to reach more engaged and more targeted audiences. Our team could guide with insight-led decision making.</div>
                <div className="zee-para-1">Our approach:</div>
                <ul>
                  <li>Special Section</li>
                  <li>Microsite</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mb-0 mb-md-5">
        <div className="row">
          <div className="col-md-8">
            <div className="zee-heading-1 mb-2">Popular Events</div>
            <div className="zee-para-1">Zee Media organises and hosts a multitude of diverse, inclusive, and impactful events face-to-face and online. Showcasing the latest ideas and technologies.</div>
          </div>
          <div className="col-md-12">
            {eventData && eventData.data && (
              <OwlCarousel className="owl-theme popular-events owl-bottom-nav" show="3" {...lifeAtZee}>
                {eventData &&
                  eventData.data &&
                  eventData.data.map((item, index) => (
                    <div className="item" key={index}>
                      <div className="popularEventWrp">
                        <div className="pe-data">
                          <div className="peIn-data">
                            <img src={require("../static/img/icons/calendar.svg").default} alt="calendar" />
                            <span>{new Date(item.event_date).toLocaleDateString("en-us", { weekday: "short", year: "numeric", month: "short", day: "numeric" })}</span>
                          </div>
                          <b>{item.event_name}</b>
                          <span>{item.description.substring(0, 125)}</span>
                        </div>
                        {console.log(item && item.id)}
                        <a href={WebRoutes.EVENTDETAILS} onClick={(e) => handleStorage(item && item.id)} className="zee-btn">
                          <span>View Event</span>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM16.03 12.53L13.03 15.53C12.88 15.68 12.69 15.75 12.5 15.75C12.31 15.75 12.12 15.68 11.97 15.53C11.68 15.24 11.68 14.76 11.97 14.47L13.69 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H13.69L11.97 9.53C11.68 9.24 11.68 8.76 11.97 8.47C12.26 8.18 12.74 8.18 13.03 8.47L16.03 11.47C16.32 11.76 16.32 12.24 16.03 12.53Z" />
                          </svg>
                        </a>
                        <img src={item && IMAGE_BASE_URL + "" + item.image} alt="Popular-Events" className="event-img" />
                      </div>
                    </div>
                  ))}
              </OwlCarousel>
            )}
          </div>
        </div>
      </div>

      <section className="CorporateAdvertise">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="CorporateSer">
                <div className="zee-heading-1">
                  <span>Corporate </span>
                  <span>Presentation</span>
                </div>
                <p className="hero-banner-para-2">Corporate Sales Presentation</p>
                {/* <a href="#!" className="zee-btn w-auto px-5 py-3" onClick={() => setOpenModal(true)}>
                  <span className="px-4">Download</span>
                </a> */}
              </div>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="AdvertiseSer">
                <div className="zee-heading-1">
                  <span>Advertise </span>
                  <span>with us</span>
                </div>
                <p className="hero-banner-para-2">To advertise on India's fastest-growing network, contact:</p>
                {/* <a href="mailto:sales@india.com" className="sales-email">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path
                      opacity="0.4"
                      d="M22 15.9399C22.0035 17.2778 21.4756 18.5623 20.5324 19.5111C19.5892 20.4599 18.3079 20.9954 16.97 20.9999H7.05C5.71214 21.0007 4.42871 20.4703 3.48177 19.5252C2.53482 18.5802 2.00186 17.2978 2 15.9599L2 15.9499C2 15.9499 2.006 11.5239 2.014 9.29694C2.01454 9.20347 2.04126 9.11202 2.09114 9.03298C2.14102 8.95393 2.21206 8.89045 2.2962 8.84973C2.38033 8.80902 2.4742 8.7927 2.56714 8.80264C2.66008 8.81257 2.74837 8.84836 2.822 8.90594C5.2 10.7909 9.447 14.2279 9.5 14.2729C10.2184 14.8471 11.1103 15.1609 12.03 15.1629C12.9517 15.1624 13.845 14.8446 14.56 14.2629C14.613 14.2269 18.767 10.8969 21.179 8.97694C21.2525 8.91853 21.341 8.882 21.4344 8.87155C21.5277 8.8611 21.6221 8.87714 21.7067 8.91784C21.7913 8.95854 21.8628 9.02225 21.9129 9.10167C21.963 9.1811 21.9897 9.27303 21.99 9.36694C22 11.5759 22 15.9399 22 15.9399Z"
                    />
                    <path d="M21.4761 5.674C21.0475 4.86673 20.4072 4.19134 19.624 3.72027C18.8407 3.24919 17.944 3.0002 17.0301 3H7.05006C6.1359 3.00002 5.23899 3.24893 4.45556 3.72001C3.67213 4.19109 3.03172 4.86658 2.60306 5.674C2.50898 5.85148 2.47969 6.05618 2.5202 6.25292C2.56072 6.44966 2.66852 6.62614 2.82506 6.752L10.2501 12.691C10.7537 13.0985 11.3822 13.3206 12.0301 13.32H12.0501C12.698 13.3206 13.3264 13.0985 13.8301 12.691L21.2551 6.752C21.4114 6.626 21.519 6.44946 21.5594 6.25273C21.5997 6.056 21.5702 5.85137 21.4761 5.674" />
                  </svg>
                  <span>sales@india.com</span>
                </a> */}
                <ul className="sales-email-list">
                  <li>
                    <i class="bi bi-envelope-fill"></i>
                    <span>mohit.sharma@zeemedia.esselgroup.com</span>
                  </li>
                  <li>
                    <i class="bi bi-envelope-fill"></i>
                    <span>r.ramakrishnan@zeemedia.esselgroup.com</span>
                  </li>
                  <li>
                    <i class="bi bi-envelope-fill"></i>
                    <span>sreyashi.banerjee@zeemedia.esselgroup.com</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {openModal ? <Corporate openModal={openModal} handleCancel={(e) => setOpenModal(false)} handleCorporate={handleCorporate} /> : null}
    </>
  );
};

const mapStateToProps = (state) => {
  const { EventReducer } = state;
  const { eventData } = EventReducer;

  return {
    eventData: EventReducer.eventData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    eventListApi: () => dispatch(eventListApi()),
    corporateForm: (data) => dispatch(corporateForm(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdvertiseWithUs);
