import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { FastScrollPDF } from "react-fast-scroll-pdf";

function PDFModal({ show, onCancel, pdfFile }) {
    const [file, setFile] = useState();
    const sourceOptions = {

        data: file

    };

    // convert file into array content
    const loadFile = (newFileName, newFile) => {
        fetch(IMAGE_BASE_URL + "/" + pdfFile).then((response) => response.arrayBuffer().then((buff) => {
            const arr = new Uint8Array(buff);
            setFile(arr);
        }));

    };

    // init load the content
    useEffect(() => {
        loadFile("", "");
    }, [])

    return (
        <Modal show={show} onHide={onCancel} fullscreen={true} >
            <Modal.Header closeButton>
                <Modal.Title>{pdfFile}</Modal.Title>
            </Modal.Header>
            <Modal.Body><FastScrollPDF source={sourceOptions} /></Modal.Body>

        </Modal>);
}

export default PDFModal;