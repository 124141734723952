import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { channelBroadcastApi } from "../../../redux/action/channel";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { WebRoutes } from "../../../routes";
import HighAlacarte from "./highAlacarte";

const ChannelPricing = ({ channelBroadcastApi, channelBroadcastData }) => {
  useEffect(() => {
    channelBroadcastApi();
  }, []);
  const [totalChannels, setTotalChannels] = useState();
  const [currentPackage, setCurrentPackage] = useState();
  const [packageDetails, setPackageDetails] = useState([]);
  const packages = channelBroadcastData && channelBroadcastData.packages;
  const packagesChannels = channelBroadcastData && channelBroadcastData.packagesChannels;
  const alacarteStandard = channelBroadcastData && channelBroadcastData.alacarteStandard;
  const alacarteHigh = channelBroadcastData && channelBroadcastData.alacarteHigh;
  const defaultPackagesChannels = channelBroadcastData && channelBroadcastData.defaultPackagesChannels;
  const handlePackageData = (id, totalApp, packageName) => {
    const filterPackage = packagesChannels && packagesChannels.length > 0 && packagesChannels.filter((q) => q.package_id == id);
    setPackageDetails(filterPackage);
    setTotalChannels(totalApp);
    setCurrentPackage(packageName);
  };
  return (
    <>
      <section class="hero-banner-page cms-banner">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mb-5">
              <ol class="zee-breadcrumb breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={WebRoutes.HOME}>Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to={WebRoutes.BROADCAST}>Products</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={WebRoutes.CHANNELPRICING}>Broadcast</Link>
                </li>
              </ol>
            </div>
            <div class="col-md-12 text-center mb-5">
              <div class="zee-heading-2 mb-5">Channel Pricing</div>
            </div>
          </div>
        </div>
      </section>

      <section className="container position-relative mt-5 mt-md-0 mb-4 pb-5">
        <div className="row">
          <div className="col-md-12">
            <div className="zmcl-navTabs-3">
              <ul class="nav nav-tabs justify-content-center" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="zmclCHMRP-tab-1" data-bs-toggle="tab" data-bs-target="#zmclCHMRP-tabData-1" type="button" role="tab" aria-controls="zmclCHMRP-tabData-1" aria-selected="true">
                    <span className="px-md-5">A-la-carte</span>
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="zmclCHMRP-tab-2" data-bs-toggle="tab" data-bs-target="#zmclCHMRP-tabData-2" type="button" role="tab" aria-controls="zmclCHMRP-tabData-2" aria-selected="false">
                    <span className="px-md-5">Packs</span>
                  </button>
                </li>
              </ul>
              <div class="tab-content">
                {/* ========== A-la-carte ========== */}
                <div class="tab-pane fade show active" id="zmclCHMRP-tabData-1" role="tabpanel" aria-labelledby="zmclCHMRP-tab-1">
                  <div className="row">
                    <div className="col-md-4 text-center mx-auto justify-content-center">
                      <div className="hero-banner-para-1 mt-4">Now you have the option of choosing your favourite channels with our A-la-carte offerings</div>
                    </div>
                    <div className="col-md-10 mx-auto">
                      <div className="zmcl-navTabs-3 mt-5 pt-4">
                        <ul class="nav nav-tabs justify-content-center" role="tablist">
                          <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="zmcl-tab-1" data-bs-toggle="tab" data-bs-target="#zmcl-tabData-1" type="button" role="tab" aria-controls="zmcl-tabData-1" aria-selected="true">
                              Standard Definition
                            </button>
                          </li>
                          <li class="nav-item" role="presentation">
                            <button class="nav-link" id="zmcl-tab-2" data-bs-toggle="tab" data-bs-target="#zmcl-tabData-2" type="button" role="tab" aria-controls="zmcl-tabData-2" aria-selected="false">
                              High Definition
                            </button>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div class="tab-pane fade show active" id="zmcl-tabData-1" role="tabpanel" aria-labelledby="zmcl-tab-1">
                            <div className="row justify-content-center py-5 g-4">
                              <HighAlacarte alacarteHigh={alacarteStandard} />
                            </div>
                          </div>
                          <div class="tab-pane fade" id="zmcl-tabData-2" role="tabpanel" aria-labelledby="zmcl-tab-2">
                            <div className="row justify-content-center py-5 g-4">
                              <HighAlacarte alacarteHigh={alacarteHigh} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* ========== Packs ========== */}
                <div class="tab-pane fade" id="zmclCHMRP-tabData-2" role="tabpanel" aria-labelledby="zmclCHMRP-tab-2">
                  <div className="row">
                    <div className="col-md-12 mt-5 mb-3">
                      <div className="zee-subhead-1" style={{ color: "var(--theme-fg-2)" }}>
                        Packages / Bouquets
                      </div>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-4">
                      <div class="nav flex-column nav-pills package-tabs" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {packages &&
                          packages.length > 0 &&
                          packages.map((item, index) => (
                            <button class={`nav-link ${index == 0 ? "active" : ""}`} id={`v-pills-tab-1`} data-bs-toggle="pill" data-bs-target={`#v-pills-1`} type="button" role="tab" aria-controls={`v-pills-1`} aria-selected="true" onClick={(e) => handlePackageData(item.id, item.totalApp, item.package_name)}>
                              <span className="pTab-check">
                                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54817 1.78658C7.99074 1.20255 8.83972 1.07561 9.44442 1.50305C10.0491 1.93049 10.1806 2.75044 9.73799 3.33447L5.36857 9.10046C5.00616 9.5787 4.30712 9.63233 3.876 9.21496L0.396265 5.8462C-0.132978 5.33384 -0.131957 4.50412 0.398547 3.99297C0.929051 3.48182 1.78815 3.48281 2.31739 3.99518L4.36923 5.98158L7.54817 1.78658Z" fill="white" />
                                </svg>
                              </span>
                              <div className="pTab-data">
                                <div className="pTab-name">
                                  <span>{item && item.package_name}</span>
                                  <b>{item && item.package_price}</b>
                                </div>
                                <div className="pTab-tenure">
                                  <strong>{item && item.totalApp} Channels</strong>
                                  <small>{item && item.package_tenure}</small>
                                </div>
                              </div>
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-1" role="tabpanel" aria-labelledby="v-pills-tab-1">
                          {packageDetails && packageDetails.length > 0 ? (
                            <>
                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <div className="brdCstHdr">
                                    {currentPackage} <span>({totalChannels} Channels)</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-4">
                                {packageDetails &&
                                  packageDetails.map((item, index) => (
                                    <div className="col-md-4" key={index}>
                                      <div className="chnlPricing">
                                        <img src={item && IMAGE_BASE_URL + "" + item.channel_logo} alt="channel logo" />
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <div className="brdCstHdr">
                                    {packages && packages.length > 0 && packages[0].package_name} <span>({packages && packages.length > 0 && packages[0].totalApp} Channels)</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row g-4">

                                {defaultPackagesChannels && defaultPackagesChannels.length > 0 && defaultPackagesChannels.map((item, index) =>
                                  <div className="col-md-4" key={index}>
                                    <div className="chnlPricing">
                                      <img src={item && IMAGE_BASE_URL + "" + item.channel_logo} alt="channel logo" />
                                    </div>
                                  </div>
                                )}

                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  const { ChannelReducer } = state;
  const { channelBroadcastData } = ChannelReducer;

  return {
    channelBroadcastData: ChannelReducer.channelBroadcastData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    channelBroadcastApi: () => dispatch(channelBroadcastApi()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPricing);
