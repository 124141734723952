import React, { useEffect } from "react";
import { connect } from "react-redux";
import { channelDigitalApi } from "../../../redux/action/channel";
import { IMAGE_BASE_URL } from "../../../redux/constant";

const Digital = ({ channelDigitalApi, channelDigitalData }) => {
  useEffect(() => {
    channelDigitalApi();
  }, []);
  return (
    <>
      <section className="hero-banner-page digital-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <img src={require("../static/img/channels/IDPL.svg").default} alt="IDPL-logo" />
              <div className="zee-subhead-1">India's most diversified digital media company</div>
              <div className="hero-banner-para-1">Indiadotcom Digital Private Limited is one of the largest digital networks in India registering millions of pageviews per month. IDPL Products are amongst the fastest growing websites and applications worldwide. With over 32 digital properties, we deliver unbiased and verified content spanning across all genres which is trusted by users across the globe.</div>
              <a href="http://idpl.ai/" className="zee-btn" target="_blank">
                Visit Website
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-5 my-md-5">
        <div className="row g-4">
          {channelDigitalData &&
            channelDigitalData.data &&
            channelDigitalData.data.map((item, index) => (
              <div className="col-lg-3 col-md-3" key={index}>
                <div className="product-wrp">
                  <img src={item && IMAGE_BASE_URL + "" + item.domain_logo} alt="zee-logo" />
                  <span>{item && item.domain_name}</span>
                  <a href={item && item.domain_link} target="_blank" rel="noreferrer" className="zee-btn-3">
                    <span>Visit Website</span>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path id="Icon" fillRule="evenodd" clipRule="evenodd" d="M5 13H16.865L13.232 17.36C12.878 17.784 12.936 18.415 13.36 18.768C13.785 19.122 14.415 19.064 14.769 18.64L19.769 12.64C19.808 12.593 19.827 12.538 19.856 12.486C19.88 12.444 19.909 12.408 19.927 12.362C19.972 12.247 19.999 12.126 19.999 12.004C19.999 12.003 20 12.001 20 12C20 11.999 19.999 11.997 19.999 11.996C19.999 11.874 19.972 11.753 19.927 11.638C19.909 11.592 19.88 11.556 19.856 11.514C19.827 11.462 19.808 11.407 19.769 11.36L14.769 5.36C14.57 5.123 14.286 5 14 5C13.774 5 13.547 5.076 13.36 5.232C12.936 5.585 12.878 6.216 13.232 6.64L16.865 11H5C4.448 11 4 11.448 4 12C4 12.552 4.448 13 5 13Z" />
                    </svg>
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { ChannelReducer } = state;
  const { channelDigitalData } = ChannelReducer;

  return {
    channelDigitalData: ChannelReducer.channelDigitalData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    channelDigitalApi: () => dispatch(channelDigitalApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Digital);
