import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { WebRoutes } from "../../../routes";
import { channelBroadcastApi } from "../../../redux/action/channel";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { parseHtml } from "../../../Utils/utils";

const MRPAgreement = ({ channelBroadcastApi, channelBroadcastData }) => {
  useEffect(() => {
    channelBroadcastApi();
  }, []);

  const mrpAgreement = channelBroadcastData && channelBroadcastData.mrpAgreement;
  console.log(mrpAgreement);
  return (
    <>
      <section class="hero-banner-page cms-banner">
        <div class="container">
          <div class="row">
            <div class="col-md-12 mb-5">
              <ol class="zee-breadcrumb breadcrumb">
                <li class="breadcrumb-item">
                  <Link to={WebRoutes.HOME}>Home</Link>
                </li>
                <li class="breadcrumb-item">
                  <Link to={WebRoutes.BROADCAST}>Products</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  <Link to={WebRoutes.CHANNELPRICING}>Broadcast</Link>
                </li>
              </ol>
            </div>
            <div class="col-md-12 text-center mb-5">
              <div class="zee-heading-2">MRP Agreements</div>
              {/* <div class="hero-banner-para-1 mb-5">(As per Regulation Dated {mrpAgreement && mrpAgreement.length > 0 && new Date(mrpAgreement[0].updated_at).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })})</div> */}
            </div>
          </div>
        </div>
      </section>

      <div class="container">
        <div class="row">
          <div class="col-md-10 mx-auto my-md-5 pt-4">
            <div class="TnC-PnP">
              <Accordion className="zmcl-accordion" defaultActiveKey="0">
                {mrpAgreement &&
                  mrpAgreement.length > 0 &&
                  mrpAgreement.map((item, index) => (
                    <>
                      {item && item.document != null ? (
                        <Accordion.Item eventKey={index}>
                          <Accordion.Header>
                            <span>{item && parseHtml(item.title)}</span>
                            <a href={item && IMAGE_BASE_URL + "" + item.document} target={"_blank"} rel="noreferrer" className="zee-btn-3">
                              Download
                            </a>
                          </Accordion.Header>
                          <Accordion.Body>
                            <span>{item && parseHtml(item.desc)}</span>
                            <a href={item && IMAGE_BASE_URL + "" + item.document} target={"_blank"} rel="noreferrer" className="zee-btn-3">
                              Download
                            </a>
                          </Accordion.Body>
                        </Accordion.Item>
                      ) : (
                        <p class="mb-md-5">{item && parseHtml(item.desc)}</p>
                      )}
                    </>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { ChannelReducer } = state;
  const { channelBroadcastData } = ChannelReducer;

  return {
    channelBroadcastData: ChannelReducer.channelBroadcastData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    channelBroadcastApi: () => dispatch(channelBroadcastApi()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MRPAgreement);
