import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./common/header";
import Footer from "./common/footer";
import { socialMediaApi } from "../../redux/action/privacy";
import { resetToast } from "../../redux/action/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DocumentMeta from "react-document-meta";
import { metaTagApi, pageBlocksApi } from "../../redux/action/home";


const BaseDashboard = ({ toastType, toastData, resetToast, socialMediaApi, socialMediaData, metaTagApi, metaTagData, pageBlocksApi, pageBlockData }) => {
  const location = useLocation();

  useEffect(() => {
    socialMediaApi();
    pageBlocksApi();
  }, []);
  useEffect(() => {
    metaTagApi();
  }, [location])

  useEffect(() => {
    if (toastType) {
      showToast();
      resetToast();
    }
  }, [toastType]);

  const showToast = () => {
    switch (toastType) {
      case "success":
        toast.success(toastData.message);
        break;
      case "error":
        toast.error(toastData.message);
        break;
      case "warning":
        toast.warning(toastData.message);
        break;
      default:
        break;
    }
  };

  // console.log(metaTagData)

  const metaData =
    metaTagData &&
    metaTagData.data &&
    metaTagData.data.filter((item) => {
      return window.location.pathname == item.page_name;
    });

  const meta = metaData &&
    metaData[0] && {
    title: metaData[0].title,
    description: metaData[0].mata_description,
    canonical: metaData[0].canonical_tag,
    meta: {
      name: {
        keywords: metaData[0].mata_keyboard,
      },
    },
  };

  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <ToastContainer />
      <Header pageBlocksApi={pageBlocksApi} pageBlockData={pageBlockData} />
      <Outlet />
      <Footer socialMediaData={socialMediaData} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { PrivacyReducer, HomeReducer, CommonReducer } = state;
  const { socialMediaData } = PrivacyReducer;

  return {
    toastData: CommonReducer.toastData,
    toastType: CommonReducer.toastType,
    socialMediaData: PrivacyReducer.socialMediaData,
    metaTagData: HomeReducer.metaTagData,
    pageBlockData: HomeReducer.pageBlockData,

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetToast: () => dispatch(resetToast()),
    metaTagApi: () => dispatch(metaTagApi()),
    socialMediaApi: () => dispatch(socialMediaApi()),
    pageBlocksApi: () => dispatch(pageBlocksApi()),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseDashboard);
