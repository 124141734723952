import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { PRIVACY_POLICYS, TERMS_CONDITIONS, SOCIAL_MEDIA, COMPANY_POLICY_URL } from "../constant";

export const privacyPolicyApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivacyRequest());
        axios
            .get(PRIVACY_POLICYS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getPrivacyDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const termsConditionsApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivacyRequest());
        axios
            .get(TERMS_CONDITIONS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getTermsDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const companyPolicyApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivacyRequest());
        axios
            .get(COMPANY_POLICY_URL, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getCompanyPolicyDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const socialMediaApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivacyRequest());
        axios
            .get(SOCIAL_MEDIA, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getSocialDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const getPrivacyRequest = data => {
    return {
        type: "PRIVACY_Data_REQUESTED",
    };
};

export const getPrivacyDataRespond = data => {
    return {
        type: "PRIVACY_DATA_RESPONSE",
        data: data,
    };
}

export const getTermsDataRespond = data => {
    return {
        type: "TERMS_DATA_RESPONSE",
        data: data,
    };
}
export const getCompanyPolicyDataRespond = data => {
    return {
        type: "COMPANY_POLICY_DATA_RESPONSE",
        data: data,
    };
}
export const getSocialDataRespond = data => {
    return {
        type: "Social_DATA_RESPONSE",
        data: data,
    };
}

export const handleError = error => {
    return {
        type: "PRIVACY_ERROR",
        error,
    };
};