import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { channelBroadcastApi } from "../../../redux/action/channel";
import { WebRoutes } from "../../../routes";
import DigitalBroadCast from "./digitalBroadCast";
import LinearBroadCast from "./linearBroadCast";

const Broadcast = ({ channelBroadcastApi, channelBroadcastData }) => {
  useEffect(() => {
    channelBroadcastApi();
  }, []);

  const linearBroadCast = channelBroadcastData && channelBroadcastData.broadCastChannelLinear;
  const digitalBroadCast = channelBroadcastData && channelBroadcastData.broadCastChannelDigital;

  return (
    <>
      <section className="hero-banner-page advertise-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto text-center">
              <div className="zee-heading-2">
                <span>On The Quest For</span>
                <span>Extraordinary Stories</span>
              </div>
              <div className="hero-banner-para-1 mx-md-5 px-md-5 justify-content-center">We draw inspiration from our audience - their struggles and triumphs, their beliefs and cultural nuances - to showcase extraordinary journeys through a million screens.</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container py-5">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex flex-column justify-content-center h-100">
              <div className="zee-heading-1">Domestic</div>
              <div className="zee-para-1 text-justify">We are the most loved storyteller, for the many Bharats that reside within our country.</div>
            </div>
          </div>
          <div className="col-md-6 offset-md-2 mt-4 mt-md-0">
            <div className="row g-4">
              <div className="col-md-5 offset-md-1">
                <div className="wwa-data">
                  <b>{channelBroadcastData && channelBroadcastData.networkData && channelBroadcastData.networkData.users}</b>
                  <span>Users</span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="wwa-data">
                  <b>{channelBroadcastData && channelBroadcastData.networkData && channelBroadcastData.networkData.social_media}</b>
                  <span>Social Media</span>
                </div>
              </div>
              <div className="col-md-5 offset-md-2">
                <div className="wwa-data">
                  <b>{channelBroadcastData && channelBroadcastData.networkData && channelBroadcastData.networkData.broadcast}</b>
                  <span>Broadcast</span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="wwa-data">
                  <b>{channelBroadcastData && channelBroadcastData.networkData && channelBroadcastData.networkData.digital}</b>
                  <span>Digital</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <div className="zmcl-navTabs-3">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="zmcl-tab-1" data-bs-toggle="tab" data-bs-target="#zmcl-tabData-1" type="button" role="tab" aria-controls="zmcl-tabData-1" aria-selected="true">
                    Linear
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="zmcl-tab-2" data-bs-toggle="tab" data-bs-target="#zmcl-tabData-2" type="button" role="tab" aria-controls="zmcl-tabData-2" aria-selected="false">
                    Digital
                  </button>
                </li>
              </ul>
              <div class="tab-content">
                <div class="tab-pane fade show active" id="zmcl-tabData-1" role="tabpanel" aria-labelledby="zmcl-tab-1">
                  <LinearBroadCast linearBroadCast={linearBroadCast} />
                </div>
                <div class="tab-pane fade" id="zmcl-tabData-2" role="tabpanel" aria-labelledby="zmcl-tab-2">
                  <DigitalBroadCast digitalBroadCast={digitalBroadCast} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="CorporateAdvertise chnlAgree">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="CorporateSer">
                <div className="zee-heading-1">
                  <span>MRP Agreements</span>
                </div>
                <Link to={WebRoutes.MRPAGREEMENTS} className="zee-btn-2 w-auto px-5 py-3">
                  <span className="px-4">Learn More</span>
                </Link>
              </div>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="AdvertiseSer">
                <div className="zee-heading-1">
                  <span>Channel Pricing</span>
                </div>
                <Link to={WebRoutes.CHANNELPRICING} className="zee-btn-2 w-auto px-5 py-3">
                  <span className="px-4">Learn More</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  const { ChannelReducer } = state;
  const { channelBroadcastData } = ChannelReducer;

  return {
    channelBroadcastData: ChannelReducer.channelBroadcastData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    channelBroadcastApi: () => dispatch(channelBroadcastApi()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);
