import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { pageBlocksApi } from "../../../redux/action/home";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { WebRoutes } from "../../../routes";

const Footer = ({ pageBlocksApi, pageBlockData, socialMediaData }) => {
  const location = useLocation();
  const [home, setHome] = useState(true);
  const [career, setCareer] = useState(true);
  const [about, setAbout] = useState(true);
  const [contact, setContact] = useState(true);
  const [digital, setDigital] = useState(true);
  const [channel, setChannel] = useState(true);
  const [investerInfo, setInvesterInfo] = useState(true);
  const [advertise, setAdvertise] = useState(true);
  const [privacy, setPrivacy] = useState(true);
  const [terms, setTerms] = useState(true);
  const [eventDetails, setEventDetails] = useState(true);

  useEffect(() => {
    handleChange();
  }, [pageBlockData]);
  const handleChange = () => {
    pageBlockData &&
      pageBlockData.data &&
      pageBlockData.data.map((item) => {
        if (item.page_name == WebRoutes.DIGITAL) {
        }
        if (item.page_name == WebRoutes.CAREER && item.status == 1) {
          setCareer(false);
        }
        if (item.page_name == WebRoutes.HOME && item.status == 1) {
          setHome(false);
        }
        if (item.page_name == WebRoutes.ABOUT && item.status == 1) {
          setAbout(false);
        }
        if (item.page_name == WebRoutes.CONTACTUS && item.status == 1) {
          setContact(false);
        }
        if (item.page_name == WebRoutes.DIGITAL && item.status == 1) {
          setDigital(false);
        }
        if (item.page_name == WebRoutes.CHANNEL && item.status == 1) {
          setChannel(false);
        }
        // handleChannel();
        if (item.page_name == WebRoutes.INVESTERINFO && item.status == 1) {
          setInvesterInfo(false);
        }
        if (item.page_name == WebRoutes.ADVERTISEWITHUS && item.status == 1) {
          setAdvertise(false);
        }
        if (item.page_name == WebRoutes.PRIVACYPOLICY && item.status == 1) {
          setPrivacy(false);
        }
        if (item.page_name == WebRoutes.TERMSCONDITIONS && item.status == 1) {
          setTerms(false);
        }
        if (item.page_name == WebRoutes.EVENTDETAILS && item.status == 1) {
          setEventDetails(false);
        }
      });
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {home ? (
                <Link to={WebRoutes.HOME} className="logo">
                  <img src={require("../static/img/logo.svg").default} alt="logo" />
                </Link>
              ) : null}
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <div className="footer-link-head">Company</div>
                  <ul className="footer-link-list">
                    {about ? (
                      <li>
                        <Link to={WebRoutes.ABOUT}>About us</Link>
                      </li>
                    ) : null}
                    {channel ? (
                      <li>
                        <Link to={WebRoutes.CHANNEL}>Channels Overview</Link>
                      </li>
                    ) : null}
                    {digital ? (
                      <li>
                        <Link to={WebRoutes.DIGITAL}>Digital</Link>
                      </li>
                    ) : null}
                    {career ? (
                      <li>
                        <Link to={WebRoutes.CAREER}>Careers</Link>
                      </li>
                    ) : null}
                    {advertise ? (
                      <li>
                        <Link to={WebRoutes.ADVERTISEWITHUS}>Advertise with us</Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footer-link-head">Others</div>
                  <ul className="footer-link-list">
                    {investerInfo ? (
                      <li>
                        <Link to={WebRoutes.INVESTERINFO}>Investor Info</Link>
                      </li>
                    ) : null}
                    {contact ? (
                      <li>
                        <Link to={WebRoutes.CONTACTUS}>Contact us</Link>
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div className="col-md-4">
                  <div className="footer-link-head">Follow Us</div>
                  <ul className="footer-link-social">
                    {socialMediaData &&
                      socialMediaData.data &&
                      socialMediaData.data.map((item, index) => (
                        <li key={index}>
                          <a href={item && item.link} target="_blank">
                            <img src={item && IMAGE_BASE_URL + "" + item.image} alt="social-link" />
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom">
            <div className="row">
              <div className="col-md-6 text-center text-md-start">
                <span>
                  <b>© {new Date().getFullYear()}, Copyright Zee Media Corporation LTD. All Rights Reserved</b>
                </span>
              </div>
              <div className="col-md-6 text-center text-md-end mt-3 mt-md-0">
                <ul className="footer-bottom-links justify-content-end justify-content-center justify-content-md-end">
                  {privacy ? (
                    <li>
                      <Link to={WebRoutes.PRIVACYPOLICY} className="footer-bottom-link">
                        <b> Privacy & Policy</b>
                      </Link>
                    </li>
                  ) : null}
                  {terms ? (
                    <li>
                      <Link to={WebRoutes.TERMSCONDITIONS} className="footer-bottom-link">
                        <b> Terms & Conditions</b>
                      </Link>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { HomeReducer } = state;
  const { pageBlockData } = HomeReducer;

  return {
    pageBlockData: HomeReducer.pageBlockData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    pageBlocksApi: () => dispatch(pageBlocksApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
