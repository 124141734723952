import axios from "axios";
import { getCommonApiHeader } from "../../Utils/utils";
import { ABOUTUS } from "../constant";


export const aboutUsDataApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getAboutRequest());
        axios
            .get(ABOUTUS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getAboutDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };

}


export const getAboutRequest = data => {
    return {
        type: "ABOUT_Data_REQUESTED",
    };
};
export const getAboutDataRespond = data => {
    return {
        type: "ABOUT_DATA_RESPONSE",
        data: data,
    };
}

export const handleError = error => {
    return {
        type: "ABOUT_ERROR",
        error,
    };
};