const initState = {
  channelEditorData: [],
  channelOverviewData: [],
  channelDigitalData: [],
  channelProductData: [],
  channelBroadcastData: [],
  error: null,
};

const ChannelReducer = (state = initState, action) => {
  const data = action?.data;
  switch (action?.type) {
    case "CHANNEL_Data_REQUESTED":
      return {
        ...state,
      };
    case "CHANNEL_EDITOR_DATA_RESPONSE":
      return {
        ...state,
        channelEditorData: data,
      };
    case "CHANNEL_Overview_DATA_RESPONSE":
      return {
        ...state,
        channelOverviewData: data,
      };
    case "CHANNEL_Digital_DATA_RESPONSE":
      return {
        ...state,
        channelDigitalData: data,
      };
    case "CHANNEL_Product_DATA_RESPONSE":
      return {
        ...state,
        channelProductData: data,
      };
    case "CHANNEL_BROADCAST_DATA_RESPONSE":
      return {
        ...state,
        channelBroadcastData: data,
      };
    case "CHANNEL_ERROR":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
export default ChannelReducer;
