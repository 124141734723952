import React, { useEffect } from "react";
import { connect } from "react-redux";
import { privacyPolicyApi } from "../../../redux/action/privacy";
import { parseHtml } from "../../../Utils/utils";

const PrivacyPolicy = ({ privacyPolicyApi, privacyData }) => {
  useEffect(() => {
    privacyPolicyApi();
  }, []);
  return (
    <>
      <section className="hero-banner-page cms-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="zee-heading-2">Privacy & Policy</div>
              <div className="hero-banner-para-1">Effective Date {privacyData && privacyData.data && new Date(privacyData.data.updated_at).toLocaleDateString("en-us", { weekday: "long", year: "numeric", month: "short", day: "numeric" })}</div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto my-md-5 pt-4">
            <div className="TnC-PnP">{privacyData && privacyData.data && parseHtml(privacyData.data.description)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { PrivacyReducer } = state;
  const { privacyData } = PrivacyReducer;

  return {
    privacyData: PrivacyReducer.privacyData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    privacyPolicyApi: () => dispatch(privacyPolicyApi()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
