import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { investerDataApi, investerSearchDataApi } from "../../../redux/action/invester";
import { IMAGE_BASE_URL } from "../../../redux/constant";
import { parseHtml } from "../../../Utils/utils";
import { Tooltip } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import PDFModal from "../policy/pdfModal";

const InvesterInfo = ({ investerDataApi, investerData, investerSearchDataApi, investerSearchData }) => {
  const [yearSearch, setYearSearch] = useState();
  const [investerType, setInvesterType] = useState();
  const [noticeDiclou, setNoticeDiclou] = useState();
  const [annualDiclou, setAnnualDiclou] = useState();
  const [disclosureActive, setDisclosureActive] = useState(false);
  const [queryParams, setQueryParams] = useSearchParams();
  const [search, setSearch] = useState();
  const [disclosureMenuFilter, setDisclosureMenuFilter] = useState();
  const [modal, setModal] = useState(false);
  const [pdfFile, setPdfFile] = useState();
  useEffect(() => {
    investerDataApi();
  }, []);
  const about = investerData && investerData.about;
  const amalgations = investerData && investerData.amalgations;
  const annuals = investerData && investerData.annuals;
  const boardDirectors = investerData && investerData.boardDirectors;
  const codeConducts = investerData && investerData.codeConducts;
  const commites = investerData && investerData.commites;
  const corporateGovernance = investerData && investerData.corporateGovernance;
  const iepfInformation = investerData && investerData.iepfInformation;
  const iepfFilesDetails = investerData && investerData.iepfFilesDetails;
  const investerService = investerData && investerData.investerService;
  const listingDocument = investerData && investerData.listingDocument;
  const notices = investerData && investerData.notices;
  const presentation = investerData && investerData.presentation;
  const quatrly = investerData && investerData.quatrly;
  const referenceInterconnect = investerData && investerData.referenceInterconnect;
  const schemaAmalga = investerData && investerData.schemaAmalga;
  const shareHolding = investerData && investerData.shareHolding;
  const stocks = investerData && investerData.stocks;
  const vendorCode = investerData && investerData.vendorCode;
  const disclosure = investerData && investerData.disclosure;
  const disclosureMenu = investerData && investerData.disclosureMenu;

  const listingDocumentYears = listingDocument && [...new Set(listingDocument.map((q) => q.invest_year))];
  const shareHoldingYear = shareHolding && [...new Set(shareHolding.map((q) => q.invest_year))];
  const presentationYear = presentation && [...new Set(presentation.map((q) => q.invest_year))];
  const annualsYear = annuals && [...new Set(annuals.map((q) => q.invest_year))];
  const quatrlyYear = quatrly && [...new Set(quatrly.map((q) => q.invest_year))];
  const schemaAmalgaYear = schemaAmalga && [...new Set(schemaAmalga.map((q) => q.invest_year))];
  const amalgationsYear = amalgations && [...new Set(amalgations.map((q) => q.invest_year))];
  const stocksYear = stocks && [...new Set(stocks.map((q) => q.invest_year))];
  const noticesYear = notices && [...new Set(notices.map((q) => q.invest_year))];
  const corporateGovernanceYear = corporateGovernance && [...new Set(corporateGovernance.map((q) => q.invest_year))];
  const referenceInterconnectYear = referenceInterconnect && [...new Set(referenceInterconnect.map((q) => q.invest_year))];

  const handleYearSearch = (e, type) => {
    if (e.target.value != "Year") {
      setInvesterType(type);
      setYearSearch(e.target.value);
      const data = { yearsearch: e.target.value, type: type };
      // investerSearchDataApi(data);
    } else {
      setInvesterType("");
      setYearSearch("");
    }
  };

  const handleSearch = (e, type) => {
    if (e.target.value != "") {
      setInvesterType(type);
      setSearch(e.target.value);
      const data = { search: e.target.value, type: type };
      // investerSearchDataApi(data);
    } else {
      setInvesterType("");
      setSearch("");
    }
  };

  const handleDisclosure = (id, name) => {
    setDisclosureActive(true);
    if (name === "Notice of meeting of Board of Directors") {
      setDisclosureMenuFilter(notices);
      setNoticeDiclou("Notice of meeting of Board of Directors");
    } else if (name === "Annual Report") {
      setDisclosureMenuFilter(annuals);
      setNoticeDiclou("annual");
    } else if (name === "Shareholding Pattern") {
      setDisclosureMenuFilter(shareHolding);
      setNoticeDiclou("Shareholding Pattern");
    } else if (name === "Schedule of analyst or institutional investor meet") {
      setDisclosureMenuFilter(presentation);
      setNoticeDiclou("Schedule of analyst or institutional investor meet");
    } else if (name === "Presentations made to analysts or institutional investors") {
      setDisclosureMenuFilter(presentation);
      setNoticeDiclou("Presentations made to analysts or institutional investors");
    } else if (name === "Disclosures under regulation 30 of LODR") {
      setDisclosureMenuFilter(stocks);
      setNoticeDiclou("Disclosures under regulation 30 of LODR");
    } else {
      const data = disclosure && disclosure.length > 0 && disclosure.filter((f) => f.menu_id == id);
      setDisclosureMenuFilter(data);
      setNoticeDiclou("");
    }
  };

  useEffect(() => {
    if (queryParams.get("q")) {
      handleDisclosure(queryParams.get("q"), "");
    }
  }, [
    setTimeout(() => {
      queryParams.get("q");
    }, 2000),
  ]);

  const handlePdfFile = (file) => {
    setPdfFile(file);
    setModal(true);
  };

  return (
    <>
      <section className="hero-banner-page InvestorInfo-banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-start">
              <div className="zee-heading-4">
                <span>Investor</span>
                <span>information</span>
              </div>
              <div className="hero-banner-para-1">Our investors / shareholders and their unflinching faith are the reason why we have reached great heights and strive for greater glory.</div>
            </div>
          </div>
        </div>
      </section>

      <section className="investor-tab-section">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="zmcl-navTabs nav-parent">
                <div className="nav nav-pills" role="tablist" aria-orientation="vertical">
                  <Link to="?q=aboutZmcl">
                    <button className={`nav-link ${queryParams.get("q") === "aboutZmcl" || queryParams.get("q") === null ? "active" : ""}`} id="aboutZmcl" data-bs-toggle="pill" data-bs-target="#aboutZmcl" type="button" role="tab" aria-controls="aboutZmcl" aria-selected="true">
                      <Tooltip placement="top" title="About ZMCL">
                        <span>About ZMCL</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=listDocument">
                    <button className={`nav-link ${queryParams.get("q") === "listDocument" ? "active" : ""}`} id="listDocument" data-bs-toggle="pill" data-bs-target="#listDocument" type="button" role="tab" aria-controls="listDocument" aria-selected="false">
                      <Tooltip placement="top" title="Listing Document">
                        <span>Listing Document</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=shareholdingPattern">
                    <button className={`nav-link ${queryParams.get("q") === "shareholdingPattern" ? "active" : ""}`} id="shareholdingPattern" data-bs-toggle="pill" data-bs-target="#shareholdingPattern" type="button" role="tab" aria-controls="shareholdingPattern" aria-selected="false">
                      <Tooltip placement="top" title="Shareholding Pattern">
                        <span>Shareholding Pattern</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=presentations">
                    <button className={`nav-link ${queryParams.get("q") === "presentations" ? "active" : ""}`} id="presentations" data-bs-toggle="pill" data-bs-target="#presentations" type="button" role="tab" aria-controls="presentations" aria-selected="false">
                      <Tooltip placement="top" title="Presentations & Releases">
                        <span>Presentations & Releases</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=boardDirectors">
                    <button className={`nav-link ${queryParams.get("q") === "boardDirectors" ? "active" : ""}`} id="boardDirectors" data-bs-toggle="pill" data-bs-target="#boardDirectors" type="button" role="tab" aria-controls="boardDirectors" aria-selected="false">
                      <Tooltip placement="top" title="Board of Directors">
                        <span>Board of Directors</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=boardCommittees">
                    <button className={`nav-link ${queryParams.get("q") === "boardCommittees" ? "active" : ""}`} id="boardCommittees" data-bs-toggle="pill" data-bs-target="#boardCommittees" type="button" role="tab" aria-controls="boardCommittees" aria-selected="false">
                      <Tooltip placement="top" title="Constitution of Board Committees">
                        <span>Constitution of Board Committees</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <button className={`nav-link ${queryParams.get("q") === "annual" || queryParams.get("q") === "quarterly" ? "" : ""}`} id="v-pills-zmcl-700" data-bs-toggle="pill" data-bs-target="#v-pills-tab-700" type="button" role="tab" aria-controls="v-pills-tab-700" aria-selected="false">
                    <div className="accordion-item zmcl-accordion">
                      <button className={`accordion-button ${queryParams.get("q") === "annual" || queryParams.get("q") === "quarterly" ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <span className="navIn">Financials</span>
                      </button>
                      <div id="collapseOne" className={`accordion-collapse collapse w-100 ${queryParams.get("q") === "annual" || queryParams.get("q") === "quarterly" ? "collapse show" : ""}`} aria-labelledby="headingOne">
                        <ul>
                          <Link to="?q=annual">
                            <li id="annual" data-bs-toggle="pill" data-bs-target="#annual" type="button" role="tab" aria-controls="annual" aria-selected="false">
                              <Tooltip placement="top" title="Annual">
                                <span>Annual</span>
                              </Tooltip>
                            </li>
                          </Link>
                          <Link to="?q=quarterly">
                            <li id="quarterly" data-bs-toggle="pill" data-bs-target="#quarterly" type="button" role="tab" aria-controls="quarterly" aria-selected="false">
                              <Tooltip placement="top" title="Quarterly">
                                <span>Quarterly</span>
                              </Tooltip>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </button>
                  <Link to="?q=codeConduct">
                    <button className={`nav-link ${queryParams.get("q") === "codeConduct" ? "active" : ""}`} id="codeConduct" data-bs-toggle="pill" data-bs-target="#codeConduct" type="button" role="tab" aria-controls="codeConduct" aria-selected="false">
                      <Tooltip placement="top" title="Code of Conduct">
                        <span>Code of Conduct</span>
                      </Tooltip>
                    </button>
                  </Link>

                  <button className={`nav-link ${queryParams.get("q") === "schemeAmalgamation" || queryParams.get("q") === "schemeArrangeAmalgamation" ? "active" : ""}`} id="v-pills-zmcl-1100" data-bs-toggle="pill" data-bs-target="#v-pills-tab-1100" type="button" role="tab" aria-controls="v-pills-tab-1100" aria-selected="false">
                    <div className="accordion-item zmcl-accordion">
                      <button className={`accordion-button ${queryParams.get("q") === "schemeAmalgamation" || queryParams.get("q") === "schemeArrangeAmalgamation" ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                        <span className="navIn">Schemes</span>
                      </button>
                      <div id="collapseTwo" className={`accordion-collapse collapse w-100 ${queryParams.get("q") === "schemeAmalgamation" || queryParams.get("q") === "schemeArrangeAmalgamation" ? "collapse show" : ""}`} aria-labelledby="headingOne">
                        <ul>
                          <Link to="?q=schemeAmalgamation">
                            <li id="schemeAmalgamation" data-bs-toggle="pill" data-bs-target="#schemeAmalgamation" type="button" role="tab" aria-controls="schemeAmalgamation" aria-selected="false">
                              <Tooltip placement="top" title="Scheme of Amalgamation">
                                <span>Scheme of Amalgamation</span>
                              </Tooltip>
                            </li>
                          </Link>
                          <Link to="?q=schemeArrangeAmalgamation">
                            <li id="schemeArrangeAmalgamation" data-bs-toggle="pill" data-bs-target="#schemeArrangeAmalgamation" type="button" role="tab" aria-controls="schemeArrangeAmalgamation" aria-selected="false">
                              <Tooltip placement="top" title="Amalgamation">
                                <span>Scheme of Arrangement and Amalgamation</span>
                              </Tooltip>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                  </button>
                  <Link to="?q=exchangeCommunication">
                    <button className={`nav-link ${queryParams.get("q") === "exchangeCommunication" ? "active" : ""}`} id="exchangeCommunication" data-bs-toggle="pill" data-bs-target="#exchangeCommunication" type="button" role="tab" aria-controls="exchangeCommunication" aria-selected="false">
                      <Tooltip placement="top" title="Stock Exchange Communication">
                        <span>Stock Exchange Communication</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=notices">
                    <button className={`nav-link ${queryParams.get("q") === "notices" ? "active" : ""}`} id="v-pills-zmcl-13" data-bs-toggle="pill" data-bs-target="#v-pills-tab-13" type="button" role="tab" aria-controls="v-pills-tab-13" aria-selected="false">
                      <Tooltip placement="top" title="Notices">
                        <span>Notices</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=corporateGovernance">
                    <button className={`nav-link ${queryParams.get("q") === "corporateGovernance" ? "active" : ""}`} id="v-pills-zmcl-14" data-bs-toggle="pill" data-bs-target="#v-pills-tab-14" type="button" role="tab" aria-controls="v-pills-tab-14" aria-selected="false">
                      <Tooltip placement="top" title="Corporate Governance">
                        <span>Corporate Governance</span>
                      </Tooltip>
                    </button>
                  </Link>
                  {/* <button className="nav-link" id="v-pills-zmcl-15" data-bs-toggle="pill" data-bs-target="#v-pills-tab-15" type="button" role="tab" aria-controls="v-pills-tab-15" aria-selected="false">
                    <Tooltip placement="top" title="Rate Card & Reference Interconnect Offer">
                      <span>Rate Card & Reference Interconnect Offer</span>
                    </Tooltip>
                  </button> */}
                  <Link to="?q=iepfInformation">
                    <button className={`nav-link ${queryParams.get("q") === "iepfInformation" ? "active" : ""}`} id="v-pills-zmcl-16" data-bs-toggle="pill" data-bs-target="#v-pills-tab-16" type="button" role="tab" aria-controls="v-pills-tab-16" aria-selected="false">
                      <Tooltip placement="top" title="IEPF Information">
                        <span>IEPF Information</span>
                      </Tooltip>
                    </button>
                  </Link>
                  <Link to="?q=vendorCodeConduct">
                    <button className={`nav-link ${queryParams.get("q") === "vendorCodeConduct" ? "active" : ""}`} id="v-pills-zmcl-17" data-bs-toggle="pill" data-bs-target="#v-pills-tab-17" type="button" role="tab" aria-controls="v-pills-tab-17" aria-selected="false">
                      <Tooltip placement="top" title="Vendor Code Of Conduct">
                        <span>Vendor Code Of Conduct</span>
                      </Tooltip>
                    </button>
                  </Link>

                  <button className={`nav-link ${disclosureActive ? "active" : ""}`} id="v-pills-zmcl-20" data-bs-toggle="pill" data-bs-target="#v-pills-tab-20" type="button" role="tab" aria-controls="v-pills-tab-20" aria-selected="false">
                    <div className="accordion-item zmcl-accordion">
                      <button className={`accordion-button ${disclosureActive ? "" : "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                        <Tooltip placement="top" title="Disclosures under Reg. 46 of the LODR">
                          <span className="navIn">Disclosures under Reg. 46 of the LODR</span>
                        </Tooltip>
                      </button>

                      <div id="collapseThree" className={`accordion-collapse collapse w-100 ${disclosureActive ? "collapse show" : ""}`} aria-labelledby="headingOne">
                        <ul>
                          {disclosureMenu &&
                            disclosureMenu.map((item, index) => (
                              <Link to={`?q=${item && item.id}`}>
                                <li key={index}>
                                  <Tooltip placement="top" title={item && item.name}>
                                    <span onClick={(e) => handleDisclosure(item && item.id, item.name)}>{item && item.name}</span>
                                  </Tooltip>
                                </li>
                              </Link>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </button>
                  <Link to="?q=investerRequest">
                    <button className={`nav-link ${queryParams.get("q") === "investerRequest" ? "active" : ""}`} id="v-pills-zmcl-18" data-bs-toggle="pill" data-bs-target="#v-pills-tab-18" type="button" role="tab" aria-controls="v-pills-tab-18" aria-selected="false">
                      <Tooltip placement="top" title="Investor Service Request">
                        <span>Investor Service Request</span>
                      </Tooltip>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="zmcl-navTabs">
                <div className="tab-content">
                  <div className={`tab-pane fade ${queryParams.get("q") === "aboutZmcl" || queryParams.get("q") === null ? "show active" : ""}`} id="aboutZmcl" role="tabpanel" aria-labelledby="aboutZmcl">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tabInnerBody">
                          <div className="title-1">{about && about.name}</div>
                          <div className="para-1">{about && parseHtml(about.description)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "listDocument" ? "show active" : ""}`} id="listDocument" role="tabpanel" aria-labelledby="v-pills-zmcl-2">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "listingDocumentYears")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "listingDocumentYears")}>
                          <option>Year</option>
                          {listingDocumentYears && listingDocumentYears.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "listingDocumentYears"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach" onClick={() => handlePdfFile(item && item.file_name)}>
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_code} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_code} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <img src={require("../static/img/icons/ViewIcon.svg").default} className="downloadIcon" alt="download" />
                                {/* <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                              </a> */}
                              </div>
                            </div>
                          ))
                        : listingDocument &&
                          listingDocument.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach" onClick={() => handlePdfFile(item.file_name)}>
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_code} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_code} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <img src={require("../static/img/icons/ViewIcon.svg").default} className="downloadIcon" alt="download" />
                                {/* <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                              </a> */}
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "shareholdingPattern" ? "show active" : ""}`} id="shareholdingPattern" role="tabpanel" aria-labelledby="shareholdingPattern">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "shareHoldingYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "shareHoldingYear")}>
                          <option>Year</option>
                          {shareHoldingYear && shareHoldingYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "shareHoldingYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach" onClick={() => handlePdfFile(item.file_name)}>
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_code} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_code} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <img src={require("../static/img/icons/ViewIcon.svg").default} className="downloadIcon" alt="download" />

                                {/* <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                              </a> */}
                              </div>
                            </div>
                          ))
                        : shareHolding &&
                          shareHolding.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach" onClick={() => handlePdfFile(item.file_name)}>
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_code} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_code} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <img src={require("../static/img/icons/ViewIcon.svg").default} className="downloadIcon" alt="download" />

                                {/* <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                              </a> */}
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "presentations" ? "show active" : ""}`} id="presentations" role="tabpanel" aria-labelledby="presentations">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "presentationYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "presentationYear")}>
                          <option>Year</option>
                          {presentationYear && presentationYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "presentationYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : presentation &&
                          presentation.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "boardDirectors" ? "show active" : ""}`} id="boardDirectors" role="tabpanel" aria-labelledby="boardDirectors">
                    <div className="row">
                      {boardDirectors &&
                        boardDirectors.map((item, index) => (
                          <div className="col-md-12" key={index}>
                            <div className="tabInnerBody bg-white">
                              <div className="title-1">
                                {item && item.name} ({item && item.emp_code})
                              </div>
                              <div className="para-1">{item && parseHtml(item.description)}</div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "boardCommittees" ? "show active" : ""}`} id="boardCommittees" role="tabpanel" aria-labelledby="boardCommittees">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="navTabList">
                          {commites &&
                            commites.map((item, index1) => (
                              <>
                                {item && item[index1] != undefined ? <div className="title">{item && item[index1] && item[index1].title}</div> : ""}
                                {index1 == 3 ? <div className="title">Corporate Social Responsibility Committee</div> : ""}
                                {index1 == 4 ? <div className="title">Risk Management Committee</div> : ""}
                                {index1 == 5 ? <div className="title">Finance Sub-Committee</div> : ""}
                                {index1 == 6 ? <div className="title">Disciplinary Committee</div> : ""}
                                <div className="talBody">
                                  <table>
                                    <tr>
                                      <th>Name</th>
                                      <th>Category</th>
                                    </tr>
                                    {item &&
                                      item.map((record) => (
                                        <tr>
                                          <td>{record && record.name}</td>
                                          <td>{record && record.designation}</td>
                                        </tr>
                                      ))}
                                  </table>
                                </div>
                              </>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "annual" ? "show active" : ""}`} id="annual" role="tabpanel" aria-labelledby="annual">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "annualsYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "annualsYear")}>
                          <option>Year</option>
                          {annualsYear && annualsYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "annualsYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : annuals &&
                          annuals.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "quarterly" ? "show active" : ""}`} id="quarterly" role="tabpanel" aria-labelledby="quarterly">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "quatrlyYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "quatrlyYear")}>
                          <option>Year</option>
                          {quatrlyYear && quatrlyYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "quatrlyYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : quatrly &&
                          quatrly.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "codeConduct" ? "show active" : ""}`} id="codeConduct" role="tabpanel" aria-labelledby="codeConduct">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="download-ttl">You can download a file</div>
                        <div className="download-para mb-5">Click on the Request download button download file</div>

                        <a href={codeConducts && IMAGE_BASE_URL + "/" + codeConducts.file_name} download={codeConducts && IMAGE_BASE_URL + "/" + codeConducts.file_name} target="_blank" rel="noreferrer" className="zee-btn px-5 py-3">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "schemeAmalgamation" ? "show active" : ""}`} id="schemeAmalgamation" role="tabpanel" aria-labelledby="schemeAmalgamation">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "schemaAmalgaYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "schemaAmalgaYear")}>
                          <option>Year</option>
                          {schemaAmalgaYear && schemaAmalgaYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "schemaAmalgaYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : schemaAmalga &&
                          schemaAmalga.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "schemeArrangeAmalgamation" ? "show active" : ""}`} id="schemeArrangeAmalgamation" role="tabpanel" aria-labelledby="schemeArrangeAmalgamation">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "amalgationsYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "amalgationsYear")}>
                          <option>Year</option>
                          {amalgationsYear && amalgationsYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "amalgationsYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : amalgations &&
                          amalgations.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "exchangeCommunication" ? "show active" : ""}`} id="exchangeCommunication" role="tabpanel" aria-labelledby="exchangeCommunication">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "stocksYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "stocksYear")}>
                          <option>Year</option>
                          {stocksYear && stocksYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "stocksYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : stocks &&
                          stocks.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "notices" ? "show active" : ""}`} id="v-pills-tab-13" role="tabpanel" aria-labelledby="v-pills-zmcl-13">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "noticesYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "noticesYear")}>
                          <option>Year</option>
                          {noticesYear && noticesYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "noticesYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : notices
                        ? notices.map(
                            (item, index) =>
                              item &&
                              item.invest_year != "Archive" && (
                                <div className="col-md-6" key={index}>
                                  <div className="download-attach">
                                    <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                    <div className="attchmntData">
                                      <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                        <b>
                                          {item && item.quarter_name} - {item && item.invest_year}
                                        </b>
                                      </Tooltip>
                                      <span>{item && item.file_size}</span>
                                    </div>
                                    <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                      <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                    </a>
                                  </div>
                                </div>
                              )
                          )
                        : notices &&
                          notices.map(
                            (item, index) =>
                              item &&
                              item.invest_year === "Archive" && (
                                <div className="col-md-6" key={index}>
                                  <div className="download-attach">
                                    <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                    <div className="attchmntData">
                                      <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                        <b>
                                          {item && item.quarter_name} - {item && item.invest_year}
                                        </b>
                                      </Tooltip>
                                      <span>{item && item.file_size}</span>
                                    </div>
                                    <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                      <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                    </a>
                                  </div>
                                </div>
                              )
                          )}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "corporateGovernance" ? "show active" : ""}`} id="v-pills-tab-14" role="tabpanel" aria-labelledby="v-pills-zmcl-14">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "corporateGovernanceYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "corporateGovernanceYear")}>
                          <option>Year</option>
                          {corporateGovernanceYear && corporateGovernanceYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "corporateGovernanceYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : corporateGovernance &&
                          corporateGovernance.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${disclosureActive ? "show active" : ""}`} id="v-pills-tab-20" role="tabpanel" aria-labelledby="v-pills-zmcl-20">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row g-4 mt-4">
                          {disclosureMenuFilter &&
                            disclosureMenuFilter.map((item, index) =>
                              item && item.file_name == null ? (
                                <div className="tabInnerBody">
                                  {item && item.menu && item.menu.name === "Details for grievance redressal and other relevant details" ? (
                                    <>
                                      <div className="para-1">{item && parseHtml(item.name)}</div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="title-1">{item && item.menu && item.menu.name}</div>
                                      <div className="para-1">{item && parseHtml(item.name)}</div>
                                    </>
                                  )}
                                </div>
                              ) : (
                                <div className="col-md-6">
                                  <div className="download-attach">
                                    <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                    <div className="attchmntData">
                                      {noticeDiclou && noticeDiclou != "Shareholding Pattern" ? (
                                        <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                          <b>
                                            {item && item.quarter_name} - {item && item.invest_year}
                                          </b>
                                        </Tooltip>
                                      ) : noticeDiclou === "Shareholding Pattern" ? (
                                        <Tooltip placement="top" title={`${item && item.quarter_code} - ${item && item.invest_year}`}>
                                          <b>
                                            {item && item.quarter_code} - {item && item.invest_year}
                                          </b>
                                        </Tooltip>
                                      ) : (
                                        <Tooltip placement="top" title={`${item && item.name}`}>
                                          <b>{item && parseHtml(item.name)}</b>
                                        </Tooltip>
                                      )}
                                      <span>{item && item.file_size}</span>
                                    </div>
                                    {item && item.file_name ? (
                                      <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                        <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                      </a>
                                    ) : null}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="v-pills-tab-15" role="tabpanel" aria-labelledby="v-pills-zmcl-15">
                    <div className="row">
                      <div className="col-md-5 offset-md-4 zee-form with-icon">
                        <input type="text" className="form-control" placeholder="Search here..." value={search} onChange={(e) => handleSearch(e, "referenceInterconnectYear")} />
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M4.16667 9.16667C4.16667 6.40917 6.40917 4.16667 9.16667 4.16667C11.9242 4.16667 14.1667 6.40917 14.1667 9.16667C14.1667 11.9242 11.9242 14.1667 9.16667 14.1667C6.40917 14.1667 4.16667 11.9242 4.16667 9.16667ZM17.2558 16.0775L14.4267 13.2475C15.3042 12.1192 15.8333 10.705 15.8333 9.16667C15.8333 5.49083 12.8425 2.5 9.16667 2.5C5.49083 2.5 2.5 5.49083 2.5 9.16667C2.5 12.8425 5.49083 15.8333 9.16667 15.8333C10.705 15.8333 12.1192 15.3042 13.2475 14.4267L16.0775 17.2558C16.24 17.4183 16.4533 17.5 16.6667 17.5C16.88 17.5 17.0933 17.4183 17.2558 17.2558C17.5817 16.93 17.5817 16.4033 17.2558 16.0775Z" />
                        </svg>
                      </div>
                      <div className="col-md-3 zee-form">
                        <select className="form-control" value={yearSearch} onChange={(e) => handleYearSearch(e, "referenceInterconnectYear")}>
                          <option>Year</option>
                          {referenceInterconnectYear && referenceInterconnectYear.map((item, index) => <option value={item}>{item}</option>)}
                        </select>
                      </div>
                    </div>
                    <div className="row g-4 mt-4">
                      {investerType && investerType == "referenceInterconnectYear"
                        ? investerSearchData &&
                          investerSearchData.data &&
                          investerSearchData.data.map((item, index) => (
                            <div className="col-md-6">
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))
                        : referenceInterconnect &&
                          referenceInterconnect.map((item, index) => (
                            <div className="col-md-6" key={index}>
                              <div className="download-attach">
                                <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                <div className="attchmntData">
                                  <Tooltip placement="top" title={`${item && item.quarter_name} - ${item && item.invest_year}`}>
                                    <b>
                                      {item && item.quarter_name} - {item && item.invest_year}
                                    </b>
                                  </Tooltip>
                                  <span>{item && item.file_size}</span>
                                </div>
                                <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                  <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                </a>
                              </div>
                            </div>
                          ))}
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "iepfInformation" ? "show active" : ""}`} id="v-pills-tab-16" role="tabpanel" aria-labelledby="v-pills-zmcl-16">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="tabInnerBody">
                          <ul>
                            <div className="row g-4 mt-4">
                              {iepfFilesDetails &&
                                iepfFilesDetails.map((item, index) => (
                                  <div className="col-md-6" key={index}>
                                    <div className="download-attach">
                                      <img src={require("../static/img/icons/pdf.svg").default} className="fileType" alt="pdf" />
                                      <div className="attchmntData">
                                        <Tooltip placement="top" title={`${item && item.name}`}>
                                          <b>{item && item.name}</b>
                                        </Tooltip>
                                      </div>
                                      <a href={IMAGE_BASE_URL + "/" + item.file_name} download={IMAGE_BASE_URL + "/" + item.file_name} target="_blank" rel="noreferrer">
                                        <img src={require("../static/img/icons/download.svg").default} className="downloadIcon" alt="download" />
                                      </a>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </ul>
                        </div>
                        <div className="tabInnerBody">
                          <div className="row">
                            <div className="col-md-12 mb-4">
                              <b>Details of Nodal Officer:</b>
                              <b>Name: {iepfInformation && iepfInformation.officer_name}</b>
                            </div>
                            <div className="col-md-4">
                              <b>Contact No. :</b>
                              <b>{iepfInformation && iepfInformation.contact_no}</b>
                            </div>
                            <div className="col-md-6">
                              <b>Email ID:</b>
                              <b>{iepfInformation && iepfInformation.email}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "vendorCodeConduct" ? "show active" : ""}`} id="v-pills-tab-17" role="tabpanel" aria-labelledby="v-pills-zmcl-17">
                    <div className="row">
                      <div className="col-md-12">
                        {vendorCode &&
                          vendorCode.map((item, index) => (
                            <div className="tabInnerBody" key={index}>
                              <div className="title-1 ppl">{item && item.title}</div>
                              <div className="para-1">{item && parseHtml(item.description)}</div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div className={`tab-pane fade ${queryParams.get("q") === "investerRequest" ? "show active" : ""}`} id="v-pills-tab-18" role="tabpanel" aria-labelledby="v-pills-zmcl-18">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <div className="download-ttl">You can download a file</div>
                        <div className="download-para mb-5">Click on the Investor Service Request download button download file</div>
                        <a href={investerService && IMAGE_BASE_URL + "/" + investerService.file_name} download={investerService && IMAGE_BASE_URL + "/" + investerService.file_name} className="zee-btn px-5 py-3" target="_blank" rel="noreferrer">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {modal && <PDFModal show={modal} onCancel={() => setModal(false)} pdfFile={pdfFile} />}
    </>
  );
};

const mapStateToProps = (state) => {
  const { InvesterReducer } = state;
  const { investerData, investerSearchData } = InvesterReducer;
  return {
    investerData: InvesterReducer.investerData,
    investerSearchData: InvesterReducer.investerSearchData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    investerDataApi: () => dispatch(investerDataApi()),
    // investerSearchDataApi: (data) => dispatch(investerSearchDataApi(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(InvesterInfo);
